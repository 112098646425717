<div class="new-deals-overview flex flex-row flex-wrap mb-3 with-side-filter-drawer">
  <div class="flex flex-row items-center gap-4">
    <div>
      <p-button
        (click)="onFilterButtonClicked()"
        [badge]="allFiltersActive().length ? allFiltersActive().length.toString() : ''"
        badgeClass="side-filter-list-p-badge-contrast"
        icon="pi pi-filter"
        label="Filter"
        outlined="true"
      />
      @if (allFiltersActive().length) {
        <p-button
          icon="pi pi-times"
          text="true"
          styleClass="side-filter-clear-button-main"
          pTooltip="Clear All"
          tooltipPosition="bottom"
          (click)="onFilterAllClear()"
        />
      }
    </div>
    <p-button
      (click)="onColumnsButtonClicked()"
      badgeClass="side-filter-list-p-badge-contrast"
      icon="fa fa-columns-3"
      label="Columns"
      outlined="true"
    />

    @for (filter of allFiltersActive(); track filter.label) {
      <div>
        <p-chip
          [label]="filter.label"
          [removable]="true"
          (onRemove)="onFilterChipRemoved(filter)"
        />
      </div>
    }
  </div>
  <div class="filters-and-stats">
    <div class="status-cards">
      @for (filterCard of filterCards(); track filterCard.label; let first = $first) {
        <div
          class="status-card"
          [class.active-filter-card]="first"
        >
          <div class="card-content">
            <div class="card-header">
              <span class="card-title">{{ filterCard.label }}</span>
              <span
                class="card-count"
                [class.active-filter-card-count]="first"
                >{{ filterCard.count }}</span
              >
            </div>
            <span class="card-value">{{ filterCard.value | number }}</span>
          </div>
        </div>
      }
    </div>
  </div>
  <div class="ml-auto flex flex-row items-center">
    <p-dropdown
      (ngModelChange)="onFilterApply()"
      [(ngModel)]="filteredDateSort"
      [options]="sortDropdownOptions"
      checkmark="true"
      class="sort-dropdown"
      optionLabel="label"
      placeholder="Sort by Date"
    ></p-dropdown>
  </div>
</div>

<p-sidebar
  [(visible)]="sideFilterBarVisible"
  [style]="{ width: '480px' }"
  appendTo="body"
  closeOnEscape="false"
  dismissible="false"
  position="right"
  showCloseIcon="false"
  styleClass="side-filter-drawer"
>
  <ng-template pTemplate="header">
    <div class="header-container">
      <span class="text-xl">Filters</span>
      <p-button
        (onClick)="onFilterCancel()"
        icon="pi pi-times"
        rounded="true"
        text="true"
      />
    </div>
  </ng-template>

  <p-divider />

  @if (sideFilterBarVisible()) {
    <!--    <p-accordion-->
    <!--      multiple="true"-->
    <!--      expandIcon="pi pi-angle-down"-->
    <!--      collapseIcon="pi pi-angle-up"-->
    <!--    >-->
    <!--      <p-accordionTab-->
    <!--        header="Agent"-->
    <!--        iconPos="end"-->
    <!--        transitionOptions="200ms cubic-bezier(0.86, 0, 0.07, 1)"-->
    <!--      >-->
    <!--        <p-multiSelect-->
    <!--          [options]="salesMembers()"-->
    <!--          [(ngModel)]="filteredSalesMember"-->
    <!--          showClear="true"-->
    <!--          filter="true"-->
    <!--          showToggleAll="false"-->
    <!--          [maxSelectedLabels]="1"-->
    <!--          placeholder="Select agents"-->
    <!--          appendTo="body"-->
    <!--        />-->
    <!--      </p-accordionTab>-->
    <!--      <p-accordionTab-->
    <!--        header="Agent Team"-->
    <!--        iconPos="end"-->
    <!--        transitionOptions="200ms cubic-bezier(0.86, 0, 0.07, 1)"-->
    <!--      >-->
    <!--        <p-multiSelect-->
    <!--          [options]="salesTeams()"-->
    <!--          [(ngModel)]="filteredSalesTeam"-->
    <!--          showClear="true"-->
    <!--          filter="true"-->
    <!--          showToggleAll="false"-->
    <!--          [maxSelectedLabels]="1"-->
    <!--          placeholder="Select agent teams"-->
    <!--          appendTo="body"-->
    <!--        />-->
    <!--      </p-accordionTab>-->
    <!--      <p-accordionTab-->
    <!--        header="Department"-->
    <!--        iconPos="end"-->
    <!--        transitionOptions="200ms cubic-bezier(0.86, 0, 0.07, 1)"-->
    <!--      >-->
    <!--        <p-multiSelect-->
    <!--          [options]="departmentsAvailable()"-->
    <!--          [(ngModel)]="filteredDepartment"-->
    <!--          showClear="true"-->
    <!--          filter="true"-->
    <!--          showToggleAll="false"-->
    <!--          [maxSelectedLabels]="1"-->
    <!--          placeholder="Select departments"-->
    <!--          appendTo="body"-->
    <!--        />-->
    <!--      </p-accordionTab>-->
    <!--      <p-accordionTab-->
    <!--        header="Call Source"-->
    <!--        iconPos="end"-->
    <!--        transitionOptions="200ms cubic-bezier(0.86, 0, 0.07, 1)"-->
    <!--      >-->
    <!--        <p-multiSelect-->
    <!--          [options]="callSourcesAvailable()"-->
    <!--          [(ngModel)]="filteredCallSources"-->
    <!--          showClear="true"-->
    <!--          filter="true"-->
    <!--          showToggleAll="false"-->
    <!--          [maxSelectedLabels]="1"-->
    <!--          placeholder="Select call sources"-->
    <!--          appendTo="body"-->
    <!--        />-->
    <!--      </p-accordionTab>-->
    <!--      <p-accordionTab-->
    <!--        header="Call Date and time"-->
    <!--        iconPos="end"-->
    <!--        transitionOptions="200ms cubic-bezier(0.86, 0, 0.07, 1)"-->
    <!--      >-->
    <!--        <div class="flex flex-column">-->
    <!--          <p-selectButton-->
    <!--            [options]="dateFilterOptions"-->
    <!--            [(ngModel)]="filteredDateRange"-->
    <!--            (ngModelChange)="onFilteredDateRangeChanged($event)"-->
    <!--            optionLabel="label"-->
    <!--            optionValue="value"-->
    <!--            styleClass="flex flex-column"-->
    <!--          />-->
    <!--          <div class="flex mb-2">-->
    <!--            <p-calendar-->
    <!--              [(ngModel)]="filteredDateRange"-->
    <!--              (ngModelChange)="onFilteredDateRangeChanged($event)"-->
    <!--              selectionMode="range"-->
    <!--              [readonlyInput]="true"-->
    <!--              [numberOfMonths]="1"-->
    <!--              [maxDate]="calendarMaxDate()"-->
    <!--              appendTo="body"-->
    <!--              hideOnDateTimeSelect="true"-->
    <!--              [firstDayOfWeek]="0"-->
    <!--              placeholder="Specific Range"-->
    <!--              class="side-filter-drawer-date-range"-->
    <!--              showButtonBar="true"-->
    <!--              [styleClass]="filteredDateRangeValid() ? '' : 'side-filter-drawer-invalid-time-range'"-->
    <!--            />-->
    <!--          </div>-->

    <!--          <div class="flex flex-row">-->
    <!--            <p-calendar-->
    <!--              [timeOnly]="true"-->
    <!--              hourFormat="12"-->
    <!--              [(ngModel)]="filteredDateRangeFirstTime"-->
    <!--              placeholder="Call Start Date Time"-->
    <!--              [styleClass]="filteredTimeRangeValid() ? '' : 'side-filter-drawer-invalid-time-range'"-->
    <!--            />-->
    <!--            <div class="mt-2 mr-2 ml-2">to</div>-->
    <!--            <p-calendar-->
    <!--              [timeOnly]="true"-->
    <!--              hourFormat="12"-->
    <!--              [(ngModel)]="filteredDateRangeSecondTime"-->
    <!--              placeholder="Call End Date Time"-->
    <!--              [styleClass]="filteredTimeRangeValid() ? '' : 'side-filter-drawer-invalid-time-range'"-->
    <!--            />-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </p-accordionTab>-->
    <!--      <p-accordionTab-->
    <!--        header="Call Duration"-->
    <!--        iconPos="end"-->
    <!--        transitionOptions="200ms cubic-bezier(0.86, 0, 0.07, 1)"-->
    <!--      >-->
    <!--        <div class="flex flex-row">-->
    <!--          <p-calendar-->
    <!--            [timeOnly]="true"-->
    <!--            hourFormat="24"-->
    <!--            showSeconds="true"-->
    <!--            [(ngModel)]="filteredDurationFirstTime"-->
    <!--            placeholder="Call Duration Start (hh:mm:ss)"-->
    <!--            [defaultDate]="durationStartDefaultDate()"-->
    <!--            [styleClass]="filteredDurationRangeValid() ? '' : 'side-filter-drawer-invalid-time-range'"-->
    <!--          />-->
    <!--          <div class="mt-2 mr-2 ml-2">to</div>-->
    <!--          <p-calendar-->
    <!--            [timeOnly]="true"-->
    <!--            hourFormat="24"-->
    <!--            showSeconds="true"-->
    <!--            [(ngModel)]="filteredDurationSecondTime"-->
    <!--            placeholder="Call Duration End (hh:mm:ss)"-->
    <!--            [defaultDate]="durationEndDefaultDate()"-->
    <!--            [styleClass]="filteredDurationRangeValid() ? '' : 'side-filter-drawer-invalid-time-range'"-->
    <!--          />-->
    <!--        </div>-->
    <!--      </p-accordionTab>-->
    <!--      <p-accordionTab-->
    <!--        header="Contract Status"-->
    <!--        iconPos="end"-->
    <!--        transitionOptions="200ms cubic-bezier(0.86, 0, 0.07, 1)"-->
    <!--      >-->
    <!--        <p-multiSelect-->
    <!--          [options]="statusesAvailable()"-->
    <!--          [(ngModel)]="filteredStatus"-->
    <!--          showClear="true"-->
    <!--          filter="true"-->
    <!--          showToggleAll="false"-->
    <!--          [maxSelectedLabels]="1"-->
    <!--          placeholder="Select contract statuses"-->
    <!--          appendTo="body"-->
    <!--        />-->
    <!--      </p-accordionTab>-->
    <!--      <p-accordionTab-->
    <!--        header="Account Phone"-->
    <!--        iconPos="end"-->
    <!--        transitionOptions="200ms cubic-bezier(0.86, 0, 0.07, 1)"-->
    <!--      >-->
    <!--        <p-inputMask-->
    <!--          [(ngModel)]="filteredAccountPhone"-->
    <!--          maxlength="10"-->
    <!--          minlength="3"-->
    <!--          placeholder="Enter the phone number in the format 05xxxxxxxx"-->
    <!--          mask="999?9999999"-->
    <!--        />-->
    <!--      </p-accordionTab>-->

    <!--      <p-accordionTab-->
    <!--        header="Account ID"-->
    <!--        iconPos="end"-->
    <!--        transitionOptions="200ms cubic-bezier(0.86, 0, 0.07, 1)"-->
    <!--      >-->
    <!--        <input-->
    <!--          type="text"-->
    <!--          pInputText-->
    <!--          [(ngModel)]="filteredAccountId"-->
    <!--          placeholder="Enter Account ID"-->
    <!--        />-->
    <!--      </p-accordionTab>-->
    <!--      <p-accordionTab-->
    <!--        header="Lead ID"-->
    <!--        iconPos="end"-->
    <!--        transitionOptions="200ms cubic-bezier(0.86, 0, 0.07, 1)"-->
    <!--      >-->
    <!--        <input-->
    <!--          type="text"-->
    <!--          pInputText-->
    <!--          [(ngModel)]="filteredLeadId"-->
    <!--          placeholder="Enter Lead ID"-->
    <!--        />-->
    <!--      </p-accordionTab>-->
    <!--      <p-accordionTab-->
    <!--        header="Deal ID"-->
    <!--        iconPos="end"-->
    <!--        transitionOptions="200ms cubic-bezier(0.86, 0, 0.07, 1)"-->
    <!--      >-->
    <!--        <input-->
    <!--          type="text"-->
    <!--          pInputText-->
    <!--          [(ngModel)]="filteredDealId"-->
    <!--          placeholder="Enter Deal ID"-->
    <!--        />-->
    <!--      </p-accordionTab>-->
    <!--    </p-accordion>-->

    <div class="footer-container">
      <p-divider></p-divider>
      <div class="button-container">
        <p-button
          label="Cancel"
          (onClick)="onFilterCancel()"
          class="apply-button"
        ></p-button>
        <p-button
          label="Clear All"
          class="apply-button"
          (onClick)="onFilterAllClear()"
        />
        <!--        <p-button-->
        <!--          label="Apply"-->
        <!--          [disabled]="!allFiltersValid()"-->
        <!--          (onClick)="onFilterApply()"-->
        <!--          class="apply-button"-->
        <!--        ></p-button>-->
      </div>
    </div>
  }
</p-sidebar>

<p-sidebar
  [(visible)]="columnsBarVisible"
  [style]="{ width: '17rem' }"
  appendTo="body"
  closeOnEscape="true"
  dismissible="true"
  position="right"
  showCloseIcon="false"
  styleClass="side-filter-drawer"
>
  <ng-template pTemplate="header">
    <div class="header-container">
      <span class="text-xl">Enabled Columns</span>
      <p-button
        (onClick)="onColumnsButtonClose()"
        icon="pi pi-times"
        rounded="true"
        text="true"
      />
    </div>
  </ng-template>

  <p-divider />

  @for (column of tableColumns(); track column.identifier) {
    @if (column.optional) {
      <div class="mb-2">
        <label class="text-sm">
          <input
            type="checkbox"
            [checked]="column.visible"
            (change)="toggleVisibility(column, !column.visible)"
          />
          {{ column.header }}
        </label>
      </div>
    }
  }
</p-sidebar>

<div class="rwa__datatable new-deals-listing-table fit-page flex flex-column">
  <p-table
    [scrollable]="true"
    (onLazyLoad)="onPageChange($event)"
    [(first)]="tableFirst"
    [columns]="enabledColumns()"
    [rowsPerPageOptions]="rowsPerPageOptions"
    [rows]="tableRowLimit"
    [showCurrentPageReport]="true"
    [totalRecords]="1"
    [value]="newDealsRecords().records"
    lazy="true"
    paginator="true"
  >
    <ng-template
      let-columns
      pTemplate="header"
    >
      <tr class="table-header">
        @for (col of columns; track col.header; let last = $last) {
          @if (last) {
            <th
              pFrozenColumn
              [frozen]="true"
              alignFrozen="right"
              class="last-frozen-column"
              [ngClass]="{ 'table-head-center': last }"
            >
              {{ col.header }}
            </th>
          } @else {
            <th [ngClass]="{ 'table-head-center': last }">
              {{ col.header }}
            </th>
          }
        }
      </tr>
    </ng-template>

    <ng-template
      let-record
      pTemplate="body"
    >
      <tr>
        @if (isTableLoading()) {
          @for (col of enabledColumns(); track col.header; let even = $even) {
            <td>
              <p-skeleton [style]="{ width: even ? '65%' : '40%' }" />
            </td>
          }
        } @else {
          @if (isEnabledColumn('deal_id')) {
            <td>
              <div style="width: 150px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis">
                <swa-listing-anchor
                  [link]="CRM_BASE_URL + '/tab/Potentials/' + record.deal_id"
                  [displayText]="record.deal_id"
                  [isExternal]="true"
                />
              </div>
            </td>
          }
          @if (isEnabledColumn('deal_health')) {
            <td>
              <div
                class="deal-health-indicator"
                (mouseover)="healthQuickviewOp.toggle($event)"
                (focus)="healthQuickviewOp.toggle($event)"
              >
                <div class="deal-health-indicator-value">
                  {{ record.deal_health }}
                </div>
              </div>
            </td>
          }
          @if (isEnabledColumn('deal_status')) {
            <td>
              <div class="deal-status-badge">
                <div class="deal-status-badge-text">{{ record.deal_status }}</div>
              </div>
            </td>
          }
          @if (isEnabledColumn('expected_value')) {
            <td>
              <div>{{ record.expected_value | number }}</div>
            </td>
          }
          @if (isEnabledColumn('deal_age')) {
            <td>
              <div>{{ record.deal_age }}</div>
            </td>
          }
          @if (isEnabledColumn('heat_map')) {
            <td>
              <div>
                <p-button
                  icon="pi pi-eye"
                  rounded="true"
                  text="true"
                  (onClick)="heatMapQuickviewOp.toggle($event)"
                />
              </div>
            </td>
          }
          @if (isEnabledColumn('account')) {
            <td>
              <div>
                <swa-listing-anchor
                  [link]="CRM_BASE_URL + '/tab/Accounts/' + record.account_id"
                  [displayText]="record.account_name"
                  [isExternal]="true"
                />
              </div>
            </td>
          }
          @if (isEnabledColumn('member')) {
            <td>
              <div class="member-avatar">
                <div class="member-bg"></div>
                <div class="member-placeholder">
                  <div class="member-text">{{ record.member }}</div>
                </div>
                <div class="member-ring"></div>
              </div>
            </td>
          }
          @if (isEnabledColumn('conversion')) {
            <td>
              <div class="deal-conversion">
                <div class="deal-conversion-high">{{ record.conversion }}</div>
              </div>
            </td>
          }
          @if (isEnabledColumn('closing_date')) {
            <td>
              <div>
                {{ record.closing_date }}
              </div>
            </td>
          }
          @if (isEnabledColumn('action')) {
            <td
              pFrozenColumn
              [frozen]="true"
              class="last-frozen-column"
              alignFrozen="right"
            >
              <div class="flex justify-content-center">
                <p-button
                  icon="fa-regular fa-pen"
                  rounded="true"
                  text="true"
                />
              </div>
            </td>
          }
        }
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      @if (isTableLoading()) {
        @for (item of Array.from(Array(tableRowLimit).keys()); track item) {
          <tr>
            @for (col of enabledColumns(); track col.header; let even = $even) {
              <td>
                <p-skeleton [style]="{ width: even ? '65%' : '40%' }" />
              </td>
            }
          </tr>
        }
      }
    </ng-template>
  </p-table>
</div>

<p-overlayPanel #healthQuickviewOp>
  <div class="new-deals-listing-health-quickview">
    <div class="quickview-content">
      <div class="quickview-header">
        <div class="health-status-label">Health status</div>
        <div class="status-indicator">
          <div class="status-value">83%</div>
        </div>
      </div>
      <div class="section-container">
        <div class="section-content">
          <div class="section-header">What going well</div>
          <div class="list-container">
            <div class="list-item">
              <div class="status-dot status-dot-success"></div>
              <div class="list-item-text">Deal age is moving good</div>
            </div>
            <div class="list-item">
              <div class="status-dot status-dot-success"></div>
              <div class="list-item-text">Activities is generally medium</div>
            </div>
            <div class="list-item">
              <div class="status-dot status-dot-success"></div>
              <div class="list-item-text">Closing date in the range</div>
            </div>
          </div>
        </div>
      </div>
      <div class="section-container">
        <div class="section-content">
          <div class="section-header">Where to focus</div>
          <div class="list-container">
            <div class="list-item">
              <div class="status-dot status-dot-warning"></div>
              <div class="list-item-text">No next action added</div>
            </div>
            <div class="list-item">
              <div class="status-dot status-dot-warning"></div>
              <div class="list-item-text">Budget is below the range for deal size</div>
            </div>
            <div class="list-item">
              <div class="status-dot status-dot-warning"></div>
              <div class="list-item-text">Objection handling needs improvement</div>
            </div>
          </div>
        </div>
      </div>
      <div class="section-container">
        <div class="section-content">
          <div class="section-header">Suggested actions</div>
          <div class="list-container">
            <div class="action-button">
              <svg
                class="button-icon"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.875 7.98828C12.875 6.25391 11.9375 4.66016 10.4375 3.76953C8.91406 2.90234 7.0625 2.90234 5.5625 3.76953C4.03906 4.66016 3.125 6.25391 3.125 7.98828C3.125 9.74609 4.03906 11.3398 5.5625 12.2305C7.0625 13.0977 8.91406 13.0977 10.4375 12.2305C11.9375 11.3398 12.875 9.74609 12.875 7.98828ZM2 7.98828C2 5.85547 3.125 3.88672 5 2.80859C6.85156 1.73047 9.125 1.73047 11 2.80859C12.8516 3.88672 14 5.85547 14 7.98828C14 10.1445 12.8516 12.1133 11 13.1914C9.125 14.2695 6.85156 14.2695 5 13.1914C3.125 12.1133 2 10.1445 2 7.98828ZM7.4375 4.80078C7.4375 4.49609 7.67188 4.23828 8 4.23828C8.30469 4.23828 8.5625 4.49609 8.5625 4.80078V7.70703L10.5547 9.01953C10.8125 9.20703 10.8828 9.55859 10.7188 9.81641C10.5313 10.0742 10.1797 10.1445 9.92188 9.95703L7.67188 8.45703C7.53125 8.36328 7.4375 8.17578 7.4375 7.98828V4.80078Z"
                  fill="#15888A"
                />
              </svg>
              <div class="button-text">Schedule a meeting</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</p-overlayPanel>

<p-overlayPanel
  #heatMapQuickviewOp
  styleClass="heatmap-card-overlay"
>
  <div class="heatmap-card">
    <div class="heatmap-card__container">
      <p-chart
        type="bar"
        [data]="chartData()"
        [options]="chartOptions()"
      />
    </div>
    <div class="heatmap-card__title">Heat Map</div>
    <div class="heatmap-card__subtitle">Past 07 days</div>

    <div class="heatmap-card__stats-box heatmap-card__stats-box--call">
      <div class="heatmap-card__stats-box-title">~ 38min</div>
      <div class="heatmap-card__stats-box-subtitle">call duration on average</div>
      <div class="heatmap-card__stats-box-indicator heatmap-card__stats-box-indicator--call"></div>
    </div>

    <div class="heatmap-card__stats-box heatmap-card__stats-box--chat">
      <div class="heatmap-card__stats-box-title">~ 25min</div>
      <div class="heatmap-card__stats-box-subtitle">chat duration on average</div>
      <div class="heatmap-card__stats-box-indicator heatmap-card__stats-box-indicator--chat"></div>
    </div>
  </div>
</p-overlayPanel>
