import { Component, OnInit, Signal, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { PanelMenuModule } from 'primeng/panelmenu';
import { MenuModule } from 'primeng/menu';
import { Button, ButtonDirective } from 'primeng/button';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { MenuItem } from 'primeng/api';
import { IHeaderDetails, IBreadCrumb } from '../shared/types/app.types';
import { Ripple } from 'primeng/ripple';
import { BadgeModule } from 'primeng/badge';
import { CALLS_ANALYSIS_PATH, NEW_DEAL_PATH, ROOT_PATH } from '../shared/constants/routes.constants';
import { GlobalStateService } from '../core/global-state.service';
import { AuthService } from '../core/auth.service';
import { ToastService } from '../core/toast.service';

@Component({
  selector: 'swa-shell',
  imports: [
    RouterLink,
    CommonModule,
    PanelMenuModule,
    MenuModule,
    Button,
    BreadcrumbModule,
    ButtonDirective,
    Ripple,
    BadgeModule,
  ],
  templateUrl: './shell.component.html',
  styleUrl: './shell.component.scss',
})
export class ShellComponent implements OnInit {
  isSideNavOpened = signal(false);

  loggedInUser: Signal<string | undefined>;

  loggedInUserRole: Signal<string[]>;

  menuItems = signal<MenuItem[]>([]);

  userMenuItems = signal<MenuItem[]>([]);

  homePageLink = CALLS_ANALYSIS_PATH;

  breadCrumbs: Signal<IBreadCrumb[]>;

  breadcrumbHome = { icon: 'pi pi-home', routerLink: ROOT_PATH };

  headerDetails: Signal<IHeaderDetails>;

  constructor(
    private globalStateService: GlobalStateService,
    private authService: AuthService,
    private toastService: ToastService,
  ) {
    this.headerDetails = this.globalStateService.headerDetails;
    this.breadCrumbs = this.globalStateService.breadCrumbs;
    this.loggedInUser = this.globalStateService.loggedInUserName;
    this.loggedInUserRole = this.globalStateService.loggedInUserRoles;
  }

  ngOnInit() {
    this.menuItems.set([
      {
        label: 'Activities',
        icon: 'fa-regular fa-wave-pulse',
        routerLink: CALLS_ANALYSIS_PATH,
      },
      {
        label: 'Sales Pipeline',
        icon: 'fa-regular fa-octagon-check',
        routerLink: NEW_DEAL_PATH,
      },
    ]);

    this.userMenuItems.set([
      {
        label: 'English',
        icon: 'pi pi-fw pi-globe',
      },
      {
        label: 'Logout',
        icon: 'pi pi-fw pi-power-off',
        command: () => {
          this.signOut();
        },
      },
    ]);
  }

  handleSideNavToggleClicked(): void {
    this.isSideNavOpened.set(!this.isSideNavOpened());
  }

  async signOut(): Promise<void> {
    try {
      await this.authService.signOut();
    } catch (e: unknown) {
      this.toastService.errorSignOut(e?.toString());
      console.log(e);
    }
  }
}
