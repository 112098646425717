import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'swa-listing-anchor',
  imports: [RouterLink],
  templateUrl: './listing-anchor.component.html',
  styleUrl: './listing-anchor.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListingAnchorComponent {
  link = input<string>();
  displayText = input<string>();

  isExternal = input<boolean>(false);
}
