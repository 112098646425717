import { Component, input } from '@angular/core';
import { ChartModule } from 'primeng/chart';
import { DropdownModule } from 'primeng/dropdown';
import { ChartConfiguration } from 'chart.js';
import { dayjsUtil } from '../../shared/utils/dayjs.util';

@Component({
  selector: 'swa-deal-detail-activity',
  imports: [ChartModule, DropdownModule],
  templateUrl: './deal-detail-activity.component.html',
  styleUrl: './deal-detail-activity.component.scss',
})
export class DealDetailActivityComponent {
  activitySummaryChartData = input<ChartConfiguration['data']>({
    labels: [
      'Dec 28, 2024',
      'Dec 29, 2024',
      'Dec 30, 2024',
      'Dec 31, 2024',
      'Jan 1, 2025',
      'Jan 2, 2025',
      'Jan 3, 2025',
    ],
    datasets: [
      {
        label: 'Call Duration Average',
        data: [50, 40, 100, 70, 20, 30, 80],
        backgroundColor: '#a257a2',
        borderColor: '#a257a2',
        maxBarThickness: 56,
      },
      {
        label: 'Chat Duration Average',
        data: [50, 40, 100, 70, 20, 30, 80].reverse(),
        backgroundColor: '#15888a',
        borderColor: '#15888a',
        maxBarThickness: 56,
        borderRadius: 4,
      },
    ],
  });
  activitySummaryChartOptions = input<ChartConfiguration['options']>({
    responsive: true,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  });

  dealStageProgressionChartData = input<ChartConfiguration['data']>({
    labels: ['Deal Stages'],
    datasets: [
      {
        label: 'Start',
        data: [2],
        backgroundColor: 'rgba(115, 115, 115, 1)',
        borderColor: 'rgba(115, 115, 115, 1)',
      },
      {
        label: 'On hold',
        data: [5],
        backgroundColor: 'rgba(233, 149, 0, 1)',
        borderColor: 'rgba(233, 149, 0, 1)',
      },
      {
        label: 'Communication',
        data: [3],
        backgroundColor: 'rgba(190, 118, 191, 1)',
        borderColor: 'rgba(190, 118, 191, 1)',
      },
    ],
  });
  dealStageProgressionChartOptions = input<ChartConfiguration['options']>({
    indexAxis: 'y',
    responsive: true,
    scales: {
      x: {
        stacked: true,
        ticks: {
          callback: (value) => {
            return dayjsUtil().add(+value, 'day').format('MMM D, YY');
          },
        },
      },
      y: {
        stacked: true,
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  });

  timeInvestmentChartData = input<ChartConfiguration['data']>({
    labels: ['Channels'],
    datasets: [
      {
        label: 'Notes',
        data: [10],
        backgroundColor: 'rgba(233, 149, 0, 1)',
        borderColor: 'rgba(233, 149, 0, 1)',
      },
      {
        label: 'Chat',
        data: [5],
        backgroundColor: 'rgba(190, 118, 191, 1)',
        borderColor: 'rgba(190, 118, 191, 1)',
      },
      {
        label: 'Inbound call',
        data: [8],
        backgroundColor: 'rgba(21, 136, 138, 1)',
        borderColor: 'rgba(21, 136, 138, 1)',
      },
    ],
  });
  timeInvestmentChartOptions = input<ChartConfiguration['options']>({
    indexAxis: 'y',
    responsive: true,
    scales: {
      x: {
        ticks: {
          callback: (value) => {
            return `${value} hr`;
          },
        },
      },
      y: {
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  });
}
