import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { GlobalStateService } from './global-state.service';
import { Observable } from 'rxjs';
import {
  IGetCADepartmentFiltersResponse,
  IGetCADetailsResponse,
  IGetCAQuickViewResponse,
  IGetCARecordsResponse,
  IGetCASalesMembersFiltersResponse,
  IGetCASalesTeamsFiltersResponse,
  IGetCAStatusFiltersResponse,
  IGetCACallSourceFiltersResponse,
} from '../shared/types/api-types';
import { dayjsUtil } from '../shared/utils/dayjs.util';

@Injectable({
  providedIn: 'root',
})
export class SalesBeApiService {
  private readonly baseUrlPrefix = '/api/sales-app';
  private readonly defaultPageLimit = 50;

  constructor(
    private http: HttpClient,
    private globalStateService: GlobalStateService,
  ) {}

  private generateBaseUrl(path: string): string {
    return `${this.baseUrlPrefix}${path}`;
  }

  private getBaseAuthHeaders(): HttpHeaders {
    const authKey = this.globalStateService.loggedInUserAuthTokenKey();
    if (!authKey) {
      return new HttpHeaders();
    }
    const authToken = localStorage.getItem(authKey);
    return new HttpHeaders({
      Authorization: `Bearer ${authToken}`,
    });
  }

  getCallAnalysisStatusFilters(): Observable<IGetCAStatusFiltersResponse> {
    return this.http.get<IGetCAStatusFiltersResponse>(this.generateBaseUrl('/status_lookup'), {
      headers: this.getBaseAuthHeaders(),
    });
  }

  getCallAnalysisSalesMembers(): Observable<IGetCASalesMembersFiltersResponse> {
    return this.http.get<IGetCASalesMembersFiltersResponse>(this.generateBaseUrl('/agents_names_lookup'), {
      headers: this.getBaseAuthHeaders(),
    });
  }

  getCallAnalysisSalesTeams(): Observable<IGetCASalesTeamsFiltersResponse> {
    return this.http.get<IGetCASalesTeamsFiltersResponse>(this.generateBaseUrl('/agents_teams_lookup'), {
      headers: this.getBaseAuthHeaders(),
    });
  }

  getCallAnalysisDepartments(): Observable<IGetCADepartmentFiltersResponse> {
    return this.http.get<IGetCADepartmentFiltersResponse>(this.generateBaseUrl('/departments_lookup'), {
      headers: this.getBaseAuthHeaders(),
    });
  }

  getCallSources(): Observable<IGetCACallSourceFiltersResponse> {
    return this.http.get<IGetCACallSourceFiltersResponse>(this.generateBaseUrl('/call_sources_lookup'), {
      headers: this.getBaseAuthHeaders(),
    });
  }

  getCallAnalysisRecords(
    pageParams?: {
      pageNumber: number;
      pageLimit: number;
      sortOrder?: 'asc' | 'desc';
    },
    filterParams?: {
      agentName?: string[];
      teamName?: string[];
      status?: string[];
      department?: string[];
      accountId?: string;
      leadId?: string;
      dealId?: string;
      account_phone?: string;
      email?: string;
      dateFrom?: Date;
      dateTo?: Date;
      callDurationFilterFrom?: number;
      callDurationFilterTo?: number;
      callSource?: string[];
    },
  ): Observable<IGetCARecordsResponse> {
    let params = new HttpParams();

    params = params.set('page_number', (pageParams?.pageNumber || 1).toString());
    params = params.set('page_limit', (pageParams?.pageLimit || this.defaultPageLimit).toString());
    params = params.set('sort_order', pageParams?.sortOrder || 'desc');

    if (filterParams?.agentName?.length) {
      filterParams.agentName.forEach((agentName) => {
        params = params.append('agent_name', agentName);
      });
    }
    if (filterParams?.teamName?.length) {
      filterParams.teamName.forEach((teamName) => {
        params = params.append('team_name', teamName);
      });
    }
    if (filterParams?.status?.length) {
      filterParams.status.forEach((status) => {
        params = params.append('status', status);
      });
    }
    if (filterParams?.department?.length) {
      filterParams.department.forEach((department) => {
        params = params.append('department', department);
      });
    }
    if (filterParams?.accountId) {
      params = params.set('account_id', filterParams.accountId);
    }
    if (filterParams?.leadId) {
      params = params.set('lead_id', filterParams.leadId);
    }
    if (filterParams?.dealId) {
      params = params.set('deal_id', filterParams.dealId);
    }
    if (filterParams?.account_phone) {
      params = params.set('account_phone', filterParams.account_phone);
    }
    if (filterParams?.email) {
      params = params.set('email', filterParams.email);
    }
    if (filterParams?.dateFrom) {
      params = params.set('date_from', dayjsUtil(filterParams.dateFrom).format('YYYY-MM-DDTHH:mm:ss'));
    }
    if (filterParams?.dateTo) {
      params = params.set('date_to', dayjsUtil(filterParams.dateTo).format('YYYY-MM-DDTHH:mm:ss'));
    }
    if (filterParams?.callDurationFilterFrom) {
      params = params.set('call_duration_filter_from', filterParams.callDurationFilterFrom.toString());
    }
    if (filterParams?.callDurationFilterTo) {
      params = params.set('call_duration_filter_to', filterParams.callDurationFilterTo.toString());

      if (!filterParams?.callDurationFilterFrom) {
        params = params.set('call_duration_filter_from', '0');
      }
    }
    if (filterParams?.callSource?.length) {
      filterParams.callSource.forEach((callSource) => {
        params = params.append('call_source', callSource);
      });
    }
    return this.http.get<IGetCARecordsResponse>(this.generateBaseUrl('/call-analysis-table'), {
      headers: this.getBaseAuthHeaders(),
      params,
    });
  }

  getCallAnalysisQuickView(callId: number): Observable<IGetCAQuickViewResponse> {
    return this.http.get<IGetCAQuickViewResponse>(this.generateBaseUrl(`/call_quickview/${callId}`), {
      headers: this.getBaseAuthHeaders(),
    });
  }

  getCallAnalysisDetails(callId: number): Observable<IGetCADetailsResponse> {
    return this.http.get<IGetCADetailsResponse>(this.generateBaseUrl(`/call-details/${callId}`), {
      headers: this.getBaseAuthHeaders(),
    });
  }
}
