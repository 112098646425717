export const ROOT_PATH = '/';
export const HOME_ROUTE = 'home';
export const HOME_PATH = `/${HOME_ROUTE}`;
export const LOGIN_ROUTE = 'login';
export const LOGIN_PATH = `/${LOGIN_ROUTE}`;
export const ACTIVITIES_MAIN_ROUTE = 'activities';
export const ACTIVITIES_MAIN_PATH = `/${ACTIVITIES_MAIN_ROUTE}`;
export const CALLS_ANALYSIS_ROUTE = 'calls-analysis';
export const CALLS_ANALYSIS_PATH = `${ACTIVITIES_MAIN_PATH}/${CALLS_ANALYSIS_ROUTE}`;
export const SALES_PIPELINE_MAIN_ROUTE = 'sales-pipeline';
export const SALES_PIPELINE_MAIN_PATH = `/${SALES_PIPELINE_MAIN_ROUTE}`;
export const NEW_DEAL_ROUTE = 'new-deals';
export const NEW_DEAL_PATH = `${SALES_PIPELINE_MAIN_PATH}/${NEW_DEAL_ROUTE}`;
export const DEAL_DETAIL_ROUTE = 'deal';
export const DEAL_DETAIL_PATH = `${SALES_PIPELINE_MAIN_PATH}/${DEAL_DETAIL_ROUTE}`;
export const DEAL_DETAIL_OVERVIEW_ROUTE = 'overview';
// export const DEAL_DETAIL_OVERVIEW_PATH = (dealId: string) =>
//   `${DEAL_DETAIL_PATH}/${dealId}/${DEAL_DETAIL_OVERVIEW_ROUTE}`;
export const DEAL_DETAIL_ACTIVITY_ROUTE = 'activity';
