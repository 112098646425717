<div class="deal-details">
  <div class="deal-header">
    <div class="deal-info">
      <div class="deal-name-info">
        <div class="deal-name">Deal Name</div>
        <div class="new-deal-badge">New Deal</div>
      </div>
      <div class="deal-details-info">
        <div class="deal-customer-name">Company Name</div>
      </div>
    </div>
    <div class="owner-info">
      <div class="owner-name">Ali Ahmed</div>
      <div class="crm-link-container cursor-pointer">
        <div class="crm-link">View in CRM</div>
        <span class="crm-icon"><i class="fa-regular fa-arrow-up-right-from-square"></i></span>
      </div>
    </div>
  </div>

  <div class="activity-summary-card">
    <div class="activity-summary-header">
      <div class="activity-summary-title-wrapper">
        <div class="activity-summary-title">Activity Summary</div>
        <span class="crm-icon"><i class="fa-regular fa-arrow-up-right-from-square"></i></span>
      </div>
      <div>
        <p-dropdown
          [options]="['Past week', 'Past month', 'Past year']"
          placeholder="-- Select --"
        ></p-dropdown>
      </div>
    </div>
    <div class="activity-chart">
      <p-chart
        height="200px"
        type="bar"
        [data]="activitySummaryChartData()"
        [options]="activitySummaryChartOptions()"
      />
    </div>
    <div class="activity-description">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam sed metus elementum, convallis massa ornare,
      imperdiet nulla. Curabitur vel augue at dolor efficitur laoreet blandit sollicitudin odio. Cras dapibus arcu
      vehicula, tincidunt orci non, pharetra nisl. Sed ac augue sit amet mi gravida rutrum. Aenean cursus sapien eget
      convallis blandit. Aliquam vestibulum sed felis ac luctus. Nulla eu metus sit amet nunc varius accumsan. Nunc
      mollis id neque non porta. Ut eu tellus quam. Aliquam a tortor in elit pretium ultricies sit amet id tortor.
      Aenean ut urna dapibus, fringilla velit sed, imperdiet sem.
      <br />
      Donec non tristique velit, sed laoreet odio. Pellentesque non velit in nulla rhoncus gravida sit amet et elit.
      Vivamus et orci felis. Interdum et malesuada fames ac ante ipsum primis in faucibus. Aenean ex purus, consectetur
      vitae pretium id, facilisis non odio. Fusce tempor quam et finibus aliquet. Nam sed dui tristique, rhoncus lectus
      varius, eleifend.
    </div>
  </div>
  <div class="activity-statistics-card">
    <div class="activity-statistics-row">
      <div class="activity-statistics-title-wrapper">
        <div class="activity-statistics-title">Activity Statistics</div>
        <span class="crm-icon"><i class="fa-regular fa-arrow-up-right-from-square"></i></span>
      </div>
      <div>
        <p-dropdown
          [options]="['Past week', 'Past month', 'Past year']"
          placeholder="-- Select --"
        ></p-dropdown>
      </div>
    </div>
    <div class="activity-statistics-row">
      <div class="activity-stats-grid">
        <div class="activity-stat-card">
          <div class="activity-stat-content">
            <div class="activity-stat-header">
              <div class="activity-stat-label">First contacted</div>
              <div class="activity-stat-sublabel">10 days since first contact.</div>
            </div>
            <div class="activity-stat-value">Jan 01, 2025</div>
          </div>
        </div>
        <div class="activity-stat-card">
          <div class="activity-stat-content">
            <div class="activity-stat-header">
              <div class="activity-stat-label">Last contacted</div>
              <div class="activity-stat-sublabel">01 days since last contacted.</div>
            </div>
            <div class="activity-stat-value">Jan 13, 2025</div>
          </div>
        </div>
        <div class="activity-stat-card">
          <div class="activity-stat-content">
            <div class="activity-stat-header">
              <div class="activity-stat-label">Calls made</div>
              <div class="activity-stat-sublabel">Reached 03 days ago</div>
            </div>
            <div class="activity-stat-value">6 outbound, 4 inbound</div>
          </div>
        </div>
        <div class="activity-stat-card">
          <div class="activity-stat-content">
            <div class="activity-stat-header">
              <div class="activity-stat-label">Chat</div>
              <div class="activity-stat-sublabel">Last chat, 01 day ago</div>
            </div>
            <div class="activity-stat-value">05 conversations</div>
          </div>
        </div>
      </div>
      <div class="activity-stats-grid mt-0">
        <div class="activity-stat-card">
          <div class="activity-stat-content">
            <div class="activity-stat-header">
              <div class="activity-stat-label">Average call duration</div>
            </div>
            <div class="flex justify-between">
              <div class="activity-stat-value">
                <span class="crm-icon mr-1"><i class="fa-regular fa-arrow-down-left"></i></span>
                Inbound 13 hrs
              </div>
              <div class="activity-stat-value">
                <span class="crm-icon mr-1"><i class="fa-regular fa-arrow-up-right"></i></span>
                Outbound 10 hrs
              </div>
            </div>
          </div>
        </div>
        <div class="activity-stat-card">
          <div class="activity-stat-content">
            <div class="activity-stat-header">
              <div class="activity-stat-label">First call duration</div>
              <div class="activity-stat-sublabel">Last chat, 05 days ago</div>
            </div>
            <div class="activity-stat-value">15</div>
          </div>
        </div>
        <div class="activity-stat-card">
          <div class="activity-stat-content">
            <div class="activity-stat-header">
              <div class="activity-stat-label">Average duration between calls</div>
            </div>
            <div class="activity-stat-value">04 days</div>
          </div>
        </div>
        <div class="activity-stat-card">
          <div class="activity-stat-content">
            <div class="activity-stat-header">
              <div class="activity-stat-label">Count of notes</div>
            </div>
            <div class="activity-stat-value">04 days</div>
          </div>
        </div>
      </div>
    </div>
    <div class="activity-statistics-row">
      <div class="time-investment-section">
        <div class="time-investment-title">Time Investment Chart</div>
        <div class="time-investment-total">
          <div class="time-investment-label">Total time engaged:</div>
          <div class="time-investment-value">960 minutes</div>
        </div>
      </div>
      <div class="time-investment-chart">
        <p-chart
          height="150px"
          width="80vw"
          type="bar"
          [data]="timeInvestmentChartData()"
          [options]="timeInvestmentChartOptions()"
        />
      </div>
    </div>
    <div class="chart-legend">
      <div class="legend-item">
        <div
          class="legend-color"
          style="background-color: rgba(233, 149, 0, 1)"
        ></div>
        <div class="legend-text">Notes</div>
      </div>
      <div class="legend-item">
        <div
          class="legend-color"
          style="background-color: rgba(190, 118, 191, 1)"
        ></div>
        <div class="legend-text">Chat</div>
      </div>
      <div class="legend-item">
        <div
          class="legend-color"
          style="background-color: rgba(21, 136, 138, 1)"
        ></div>
        <div class="legend-text">Inbound call</div>
      </div>
      <div class="legend-item">
        <div
          class="legend-color"
          style="background-color: rgba(149, 232, 228, 1)"
        ></div>
        <div class="legend-text">Outbound call</div>
      </div>
    </div>
  </div>

  <div class="activity-list">
    <div class="activity-header">
      <div class="header-content">
        <h1 class="activity-title">Activity List</h1>
        <div class="activity-filters">
          <button class="filter-btn filter-btn-active">All (18)</button>
          <button class="filter-btn">Calls (10)</button>
          <button class="filter-btn">Chats (15)</button>
          <button class="filter-btn">Notes (3)</button>
        </div>
      </div>
      <div class="activity-controls">
        <button class="control-btn">
          <span class="control-text">Sort by : Latest</span>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/d024ee9e560c41b3ae54188451753afc/d873877dd7ce9e82d64f1496421c7f304984423f97d5e9b5ed91a0dc9c580b66?placeholderIfAbsent=true"
            class="control-icon"
            alt="Sort icon"
          />
        </button>
        <button class="control-btn">
          <span class="control-text">Past week</span>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/d024ee9e560c41b3ae54188451753afc/9647f750a9fd4d31c5ab4d2038322cd531733722ced2b5bc031bd42766b42679?placeholderIfAbsent=true"
            class="control-icon"
            alt="Calendar icon"
          />
        </button>
      </div>
    </div>

    <div class="activity-cards">
      <div class="activity-card">
        <div class="card-field">
          <h2 class="field-label">Activity number</h2>
          <p class="field-value">237648</p>
        </div>
        <div class="card-field">
          <h2 class="field-label">Date</h2>
          <p class="field-value">16 Feb 2025</p>
        </div>
        <div class="card-field">
          <h2 class="field-label">Duration</h2>
          <p class="field-value">35 minutes</p>
        </div>
        <div class="card-field">
          <h2 class="field-label">Stage</h2>
          <p class="field-value">Communication</p>
        </div>
        <div class="card-field">
          <h2 class="field-label">Sequence</h2>
          <p class="field-value">04</p>
        </div>
        <div class="card-actions">
          <div class="action-buttons">
            <button class="action-btn">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/d024ee9e560c41b3ae54188451753afc/13db9a8685e8c3471a1bae0a5f7b39ca9cb0721ae1cb36d7b5a87002ca75587f?placeholderIfAbsent=true"
                class="action-icon"
                alt="Edit"
              />
            </button>
            <button class="action-btn">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/d024ee9e560c41b3ae54188451753afc/124213d73b37163a4633d0cc43508cbd81ffafe91fffe48bd9dc8fc926ebdded?placeholderIfAbsent=true"
                class="action-icon"
                alt="Delete"
              />
            </button>
          </div>
        </div>
      </div>

      <!-- Repeated activity cards with the same structure -->
      <div class="activity-card">
        <!-- Same structure as above, repeated for each card -->
        <div class="card-field">
          <h2 class="field-label">Activity number</h2>
          <p class="field-value">237648</p>
        </div>
        <div class="card-field">
          <h2 class="field-label">Date</h2>
          <p class="field-value">16 Feb 2025</p>
        </div>
        <div class="card-field">
          <h2 class="field-label">Duration</h2>
          <p class="field-value">35 minutes</p>
        </div>
        <div class="card-field">
          <h2 class="field-label">Stage</h2>
          <p class="field-value">Communication</p>
        </div>
        <div class="card-field">
          <h2 class="field-label">Sequence</h2>
          <p class="field-value">04</p>
        </div>
        <div class="card-actions">
          <div class="action-buttons">
            <button class="action-btn">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/d024ee9e560c41b3ae54188451753afc/13db9a8685e8c3471a1bae0a5f7b39ca9cb0721ae1cb36d7b5a87002ca75587f?placeholderIfAbsent=true"
                class="action-icon"
                alt="Edit"
              />
            </button>
            <button class="action-btn">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/d024ee9e560c41b3ae54188451753afc/124213d73b37163a4633d0cc43508cbd81ffafe91fffe48bd9dc8fc926ebdded?placeholderIfAbsent=true"
                class="action-icon"
                alt="Delete"
              />
            </button>
          </div>
        </div>
      </div>

      <!-- Additional cards following the same pattern -->
      <!-- Cards 3-7 with identical structure -->

      <!--      <div class="activity-card activity-card-empty"></div>-->
    </div>
  </div>
</div>
