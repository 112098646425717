<div class="deal-details">
  <div class="deal-header">
    <div class="deal-info">
      <div class="company-info">
        <div class="company-name">Company Name</div>
        <div class="new-deal-badge">New Deal</div>
      </div>
      <div class="deal-details-info">
        <div class="deal-number">Deal# 324794</div>
        <div class="deal-separator">|</div>
        <div class="deal-creation-date">Deal Created: Jan, 01, 2025</div>
      </div>
    </div>
    <div class="owner-info">
      <div class="owner-name">Ali Ahmed</div>
      <div class="crm-link-container cursor-pointer">
        <div class="crm-link">View in CRM</div>
        <span class="crm-icon"><i class="fa-regular fa-arrow-up-right-from-square"></i></span>
      </div>
    </div>
  </div>
  <div class="deal-stats">
    <div class="stat-card expected-amount-card">
      <div class="stat-card-content">
        <div class="stat-label">Expected Amount</div>
        <div class="stat-value-container">
          <div class="stat-value">SAR 10,000</div>
          <div class="stat-update">Updated: 7 days ago</div>
        </div>
      </div>
    </div>
    <div class="stat-card deal-stage-card">
      <div class="stat-card-content">
        <div class="stat-label">Deal Stage</div>
        <div class="stat-value-container">
          <div class="stat-value">Demo & Presentation</div>
          <div class="stat-update">Updated: 7 days ago</div>
        </div>
      </div>
    </div>
    <div class="stat-card activity-card">
      <div class="stat-card-content">
        <div class="stat-label">Activity</div>
        <div class="stat-value-container">
          <div class="stat-value">10 Calls | 15 Chats</div>
          <div class="stat-update">Last Contacted: 1 day ago</div>
        </div>
      </div>
    </div>
    <div class="stat-card closing-date-card">
      <div class="stat-card-content">
        <div class="stat-label">Closing Date</div>
        <div class="stat-value-container">
          <div class="stat-value">Jan 15, 2025</div>
          <div class="stat-update">Updated: 2 days ago</div>
        </div>
      </div>
    </div>
  </div>
  <div class="main-content">
    <div class="content-wrapper">
      <div class="left-column">
        <div class="left-column-content">
          <div class="deal-progression">
            <div class="progression-header">
              <div class="progression-title">Deal Stage Progression</div>
              <div class="conversion-info">
                <div class="conversion-label">Conversion likelihood:</div>
                <div>
                  <p-dropdown
                    [options]="['High Conversion', 'Medium Conversion', 'Low Conversion']"
                    placeholder="-- Select --"
                  ></p-dropdown>
                </div>
              </div>
            </div>
            <div class="deal-stage-progression-chart">
              <p-chart
                height="100px"
                width="65vw"
                type="bar"
                [data]="dealStageProgressionChartData()"
                [options]="dealStageProgressionChartOptions()"
              />
            </div>
            <div class="chart-legend">
              <div class="legend-item">
                <div
                  class="legend-color"
                  style="background-color: rgba(115, 115, 115, 1)"
                ></div>
                <div class="legend-text">Start</div>
              </div>
              <div class="legend-item">
                <div
                  class="legend-color"
                  style="background-color: rgba(233, 149, 0, 1)"
                ></div>
                <div class="legend-text">On hold</div>
              </div>
              <div class="legend-item">
                <div
                  class="legend-color"
                  style="background-color: rgba(190, 118, 191, 1)"
                ></div>
                <div class="legend-text">Communication</div>
              </div>
              <div class="legend-item">
                <div
                  class="legend-color"
                  style="background-color: rgba(59, 130, 246, 1)"
                ></div>
                <div class="legend-text">Demo</div>
              </div>
              <div class="legend-item">
                <div
                  class="legend-color"
                  style="background-color: rgba(21, 136, 138, 1)"
                ></div>
                <div class="legend-text">Proposal</div>
              </div>
              <div class="legend-item">
                <div
                  class="legend-color"
                  style="background-color: rgba(5, 150, 105, 1)"
                ></div>
                <div class="legend-text">Won</div>
              </div>
            </div>
          </div>
          <div class="account-details-grid">
            <div class="detail-group-row">
              <div class="detail-group">
                <div class="detail-label">Account Name</div>
                <div class="detail-value">Evercart Company</div>
              </div>
              <div class="detail-group">
                <div class="detail-label">Category</div>
                <div class="detail-value">New Business</div>
              </div>
              <div class="detail-group">
                <div class="detail-label">Status</div>
                <div class="detail-value">In-trial</div>
              </div>
            </div>
            <div class="detail-group-row">
              <div class="detail-group">
                <div class="detail-label">SQL Score</div>
                <div class="detail-value">57 - High</div>
              </div>
              <div class="detail-group">
                <div class="detail-label">Business Name</div>
                <div class="detail-value">Evercart Supermarket</div>
              </div>
              <div class="detail-group">
                <div class="detail-label">Business Type</div>
                <div class="detail-value">Supermarket</div>
              </div>
            </div>
            <div class="detail-group-row">
              <div class="detail-group">
                <div class="detail-label">Sector</div>
                <div class="detail-value">Retail</div>
              </div>
              <div class="detail-group">
                <div class="detail-label">Last Quoted Amount</div>
                <div class="detail-value">SAR 10,000</div>
              </div>
              <div class="detail-group">
                <div class="detail-label">Expected Deal Value</div>
                <div class="detail-value">SAR 7,000</div>
              </div>
            </div>
            <div class="detail-group-row">
              <div class="detail-group">
                <div class="detail-label">Actual Deal value</div>
                <div class="detail-value">N/A</div>
              </div>
              <div class="detail-group">
                <div class="detail-label">Invoices</div>
                <div class="detail-group-with-icon">
                  <div class="detail-value">02</div>
                  <span class="crm-icon"><i class="fa-regular fa-arrow-up-right-from-square"></i></span>
                </div>
              </div>
              <div class="detail-group">
                <div class="detail-label">Quotation Issued</div>
                <div class="detail-group-with-icon">
                  <div class="detail-value">02</div>
                  <span class="crm-icon"><i class="fa-regular fa-arrow-up-right-from-square"></i></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right-column">
        <div class="health-status-card">
          <div class="health-status-content">
            <div class="health-status-sections">
              <div class="health-status-header">
                <div class="health-status-title">Health status</div>
                <div class="health-status-info">
                  <div class="health-status-score">
                    <span class="crm-icon mr-1"><i class="fa-regular fa-arrow-up"></i></span>
                    <div class="health-status-value">02</div>
                  </div>
                  <div class="health-status-period">Last 07 days</div>
                </div>
              </div>
              <div class="health-status-section">
                <div class="health-status-section-title">What going well</div>
                <div class="health-status-list">
                  <div class="health-status-item">Deal age is moving good</div>
                  <div class="health-status-item">Agent have a demo meeting next Sunday</div>
                  <div class="health-status-item">Agent have a demo meeting next Sunday</div>
                  <div class="health-status-item">Agent have a demo meeting next Sunday</div>
                </div>
              </div>
              <div class="health-status-section">
                <div class="health-status-section-title">Where to focus</div>
                <div class="health-status-list">
                  <div class="health-status-item">
                    <div class="foo"></div>
                    Activities is very low from agent
                  </div>
                  <div class="health-status-item">Objection handling needs improvement</div>
                  <div class="health-status-item">Objection handling needs improvement</div>
                  <div class="health-status-item">Objection handling needs improvement</div>
                </div>
              </div>
              <div class="health-status-section">
                <div class="health-status-section-title">Alerts!</div>
                <div class="health-status-list">
                  <div class="health-status-item">Budget is outside the range for deal size</div>
                  <div class="health-status-item">Customer have questions with no answer</div>
                  <div class="health-status-item">Customer have questions with no answer</div>
                  <div class="health-status-item">Customer have questions with no answer</div>
                </div>
              </div>
            </div>
            <div class="suggested-actions">
              <div class="suggested-actions-title">Suggested actions</div>
              <div class="suggested-actions-list">
                <div class="suggested-action-button">
                  <span class="crm-icon mr-1"><i class="fa-regular fa-glasses-round"></i></span>
                  <div class="suggested-action-text">Review with Ahmad</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="contact-sales-info">
    <div class="contact-sales-wrapper">
      <div class="contact-info-column">
        <div class="contact-info-card">
          <div class="contact-info-header">
            <div class="contact-info-title">Contact Information</div>
            <span class="crm-icon"><i class="fa-regular fa-arrow-up-right-from-square"></i></span>
          </div>
          <div class="contact-info-grid">
            <div class="contact-info-item">
              <div class="contact-info-label">Contact Name</div>
              <div class="contact-info-value">Muhammad Abdullah</div>
            </div>
            <div class="contact-info-item">
              <div class="contact-info-label">Contact Email</div>
              <div class="contact-info-value">m.abdullah&#64;gmail.com</div>
            </div>
          </div>
          <div class="contact-info-grid">
            <div class="contact-info-item">
              <div class="contact-info-label">Phone Number</div>
              <div class="contact-info-value">+966 537 100 892</div>
            </div>
            <div class="contact-info-item">
              <div class="contact-info-label">Number of contracts</div>
              <div class="contact-info-value">02</div>
            </div>
          </div>
          <div class="crm-link-wrapper">
            <div class="crm-link-label">CRM Link</div>
            <div class="crm-link-value">
              <a
                href="http://www.zoho.com/284872/23479080234"
                target="_blank"
                rel="noopener noreferrer"
                >www.zoho.com/284872/23479080234</a
              >
            </div>
          </div>
        </div>
      </div>
      <div class="sales-info-column">
        <div class="sales-info-card">
          <div class="sales-info-title">Sales Information</div>
          <div class="sales-info-grid">
            <div class="sales-info-item">
              <div class="sales-info-label">Sales Owner</div>
              <div class="sales-info-value">Muhammad Abdullah</div>
            </div>
            <div class="sales-info-item">
              <div class="sales-info-label">Pre-Sales Owner</div>
              <div class="sales-info-value">Muhammad Osama</div>
            </div>
          </div>
          <div class="sales-info-grid">
            <div class="sales-info-item">
              <div class="sales-info-label">CSM Owner</div>
              <div class="sales-info-value">Shoaib Khan</div>
            </div>
            <div class="sales-info-item">
              <div class="sales-info-label">Lead ID</div>
              <div class="sales-info-value">m.abdullah&#64;gmail.com</div>
            </div>
          </div>
          <div class="sales-info-grid">
            <div class="sales-info-item">
              <div class="sales-info-label">Days Since Lead Created</div>
              <div class="sales-info-value">05</div>
            </div>
            <div class="sales-info-item">
              <div class="sales-info-label">Days Since Deal Created</div>
              <div class="sales-info-value">07</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="activity-summary-card">
    <div class="activity-summary-header">
      <div class="activity-summary-title-wrapper">
        <div class="activity-summary-title">Activity Summary</div>
        <span class="crm-icon"><i class="fa-regular fa-arrow-up-right-from-square"></i></span>
      </div>
      <div>
        <p-dropdown
          [options]="['Past week', 'Past month', 'Past year']"
          placeholder="-- Select --"
        ></p-dropdown>
      </div>
      <!--      <div class="activity-period-selector">-->
      <!--        <div class="activity-period-text">Past week</div>-->
      <!--        <img-->
      <!--          loading="lazy"-->
      <!--          src="https://cdn.builder.io/api/v1/image/assets/d024ee9e560c41b3ae54188451753afc/3851269e449063d476f45b8ec65dd1483f62a98e82e6a0aa1d4a1c90299fb2b1?apiKey=d024ee9e560c41b3ae54188451753afc&"-->
      <!--          class="activity-period-icon"-->
      <!--          alt="Period Selector Icon"-->
      <!--        />-->
      <!--      </div>-->
    </div>
    <div class="activity-chart">
      <p-chart
        height="200px"
        type="bar"
        [data]="activitySummaryChartData()"
        [options]="activitySummaryChartOptions()"
      />
    </div>
    <div class="activity-description">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam sed metus elementum, convallis massa ornare,
      imperdiet nulla. Curabitur vel augue at dolor efficitur laoreet blandit sollicitudin odio. Cras dapibus arcu
      vehicula, tincidunt orci non, pharetra nisl. Sed ac augue sit amet mi gravida rutrum. Aenean cursus sapien eget
      convallis blandit. Aliquam vestibulum sed felis ac luctus. Nulla eu metus sit amet nunc varius accumsan. Nunc
      mollis id neque non porta. Ut eu tellus quam. Aliquam a tortor in elit pretium ultricies sit amet id tortor.
      Aenean ut urna dapibus, fringilla velit sed, imperdiet sem.
      <br />
      Donec non tristique velit, sed laoreet odio. Pellentesque non velit in nulla rhoncus gravida sit amet et elit.
      Vivamus et orci felis. Interdum et malesuada fames ac ante ipsum primis in faucibus. Aenean ex purus, consectetur
      vitae pretium id, facilisis non odio. Fusce tempor quam et finibus aliquet. Nam sed dui tristique, rhoncus lectus
      varius, eleifend.
    </div>
  </div>
  <div class="activity-statistics-card">
    <div class="activity-statistics-row">
      <div class="activity-statistics-title-wrapper">
        <div class="activity-statistics-title">Activity Statistics</div>
        <span class="crm-icon"><i class="fa-regular fa-arrow-up-right-from-square"></i></span>
      </div>
      <div>
        <p-dropdown
          [options]="['Past week', 'Past month', 'Past year']"
          placeholder="-- Select --"
        ></p-dropdown>
      </div>
    </div>
    <div class="activity-statistics-row">
      <div class="activity-stats-grid">
        <div class="activity-stat-card">
          <div class="activity-stat-content">
            <div class="activity-stat-header">
              <div class="activity-stat-label">First contacted</div>
              <div class="activity-stat-sublabel">10 days since first contact.</div>
            </div>
            <div class="activity-stat-value">Jan 01, 2025</div>
          </div>
        </div>
        <div class="activity-stat-card">
          <div class="activity-stat-content">
            <div class="activity-stat-header">
              <div class="activity-stat-label">Last contacted</div>
              <div class="activity-stat-sublabel">01 days since last contacted.</div>
            </div>
            <div class="activity-stat-value">Jan 13, 2025</div>
          </div>
        </div>
        <div class="activity-stat-card">
          <div class="activity-stat-content">
            <div class="activity-stat-header">
              <div class="activity-stat-label">Calls made</div>
              <div class="activity-stat-sublabel">Reached 03 days ago</div>
            </div>
            <div class="activity-stat-value">6 outbound, 4 inbound</div>
          </div>
        </div>
        <div class="activity-stat-card">
          <div class="activity-stat-content">
            <div class="activity-stat-header">
              <div class="activity-stat-label">Chat</div>
              <div class="activity-stat-sublabel">Last chat, 01 day ago</div>
            </div>
            <div class="activity-stat-value">05 conversations</div>
          </div>
        </div>
      </div>
    </div>
    <div class="activity-statistics-row">
      <div class="time-investment-section">
        <div class="time-investment-title">Time Investment Chart</div>
        <div class="time-investment-total">
          <div class="time-investment-label">Total time engaged:</div>
          <div class="time-investment-value">960 minutes</div>
        </div>
      </div>
      <div class="time-investment-chart">
        <p-chart
          height="150px"
          width="80vw"
          type="bar"
          [data]="timeInvestmentChartData()"
          [options]="timeInvestmentChartOptions()"
        />
      </div>
    </div>
    <div class="chart-legend">
      <div class="legend-item">
        <div
          class="legend-color"
          style="background-color: rgba(233, 149, 0, 1)"
        ></div>
        <div class="legend-text">Notes</div>
      </div>
      <div class="legend-item">
        <div
          class="legend-color"
          style="background-color: rgba(190, 118, 191, 1)"
        ></div>
        <div class="legend-text">Chat</div>
      </div>
      <div class="legend-item">
        <div
          class="legend-color"
          style="background-color: rgba(21, 136, 138, 1)"
        ></div>
        <div class="legend-text">Inbound call</div>
      </div>
      <div class="legend-item">
        <div
          class="legend-color"
          style="background-color: rgba(149, 232, 228, 1)"
        ></div>
        <div class="legend-text">Outbound call</div>
      </div>
    </div>
  </div>
</div>
