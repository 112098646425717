import { Component, computed, model, ModelSignal, signal, OnInit, input } from '@angular/core';
import { DatePipe, DecimalPipe, NgClass } from '@angular/common';
import { Button } from 'primeng/button';
import { ChipModule } from 'primeng/chip';
import { TooltipModule } from 'primeng/tooltip';
// import {
//   IGetCACallSourceFiltersResponse,
//   IGetCADepartmentFiltersResponse,
//   IGetCAQuickViewResponse,
//   IGetCARecordsResponse,
//   IGetCASalesMembersFiltersResponse,
//   IGetCASalesTeamsFiltersResponse,
//   IGetCAStatusFiltersResponse, IGetCATableRecord,
// } from '../../../shared/types/api-types';
import { dayjsUtil } from '../../shared/utils/dayjs.util';
import { SalesBeApiService } from '../../core/sales-be-api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalStateService } from '../../core/global-state.service';
// import { finalize } from 'rxjs';
import { environment } from '../../../environments/environment';
import { NEW_DEAL_PATH } from '../../shared/constants/routes.constants';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { AccordionModule } from 'primeng/accordion';
import { CalendarModule } from 'primeng/calendar';
import { DividerModule } from 'primeng/divider';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { CroppedTextComponent } from '../../shared/components/cropped-text/cropped-text.component';
import { ListingAnchorComponent } from '../../shared/components/listing-anchor/listing-anchor.component';
import { SkeletonModule } from 'primeng/skeleton';
import { TableModule } from 'primeng/table';
import { IGetNewDealsRecordsResponse } from '../../shared/types/api-types';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ChartModule } from 'primeng/chart';
import { ChartConfiguration } from 'chart.js';

interface IFilterCard {
  label: string;
  count: number;
  value: number;
}

interface Column {
  identifier: string;
  field: string;
  header: string;
  optional?: boolean;
  visible?: boolean;
}

enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

// interface UrlQueryParams {
//   visibleColumns?: string[];
//   sortOrder?: SortOrder;
//   tableRowLimit?: string;
//   page?: string;
// }

interface ActiveFilter {
  label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  models: ModelSignal<any>[];
}

@Component({
  selector: 'swa-new-deals-list',
  imports: [
    DecimalPipe,
    Button,
    ChipModule,
    TooltipModule,
    DropdownModule,
    FormsModule,
    AccordionModule,
    CalendarModule,
    DividerModule,
    InputMaskModule,
    InputTextModule,
    MultiSelectModule,
    SelectButtonModule,
    SidebarModule,
    CroppedTextComponent,
    DatePipe,
    ListingAnchorComponent,
    SkeletonModule,
    TableModule,
    NgClass,
    OverlayPanelModule,
    ChartModule,
  ],
  templateUrl: './new-deals-list.component.html',
  styleUrl: './new-deals-list.component.scss',
})
export class NewDealsListComponent implements OnInit {
  isTableLoading = signal(false);

  sideFilterBarVisible = model(false);
  columnsBarVisible = model(false);
  quickViewModalVisible = model(false);

  // quickViewModalData = model<IGetCAQuickViewResponse>();

  // filteredSalesMember = model<string[]>();
  // filteredSalesTeam = model<string[]>();
  // filteredStatus = model<string[]>();
  // filteredAccountId = model<string>();
  // filteredLeadId = model<string>();
  // filteredDealId = model<string>();
  // filteredListen = model<string[]>();
  // filteredDepartment = model<string[]>();
  // filteredAccountPhone = model<string>();
  // filteredCallSources = model<string[]>();
  //
  // filteredDateRange = model<[Date | undefined, Date | undefined]>();
  // filteredDateRangeFirstTime = model<Date>();
  // filteredDateRangeSecondTime = model<Date>();
  //
  // filteredDurationFirstTime = model<Date>();
  // filteredDurationSecondTime = model<Date>();

  newDealsRecords = signal<IGetNewDealsRecordsResponse>({
    total_records: 0,
    total_pages: 0,
    current_page: 1,
    records: [
      {
        deal_id: '123456',
        deal_health: '83%',
        deal_status: 'Proposal',
        expected_value: 4900,
        deal_age: '4 days',
        heat_map: '',
        account_id: '123456',
        account_name: 'Buz Bus',
        member: 'ZM',
        conversion: 'High',
        closing_date: '20 Jan 2025',
      },
    ],
  });

  sortDropdownOptions = [
    { label: '↓ Date: Newest First', value: SortOrder.DESC },
    { label: '↑ Date: Oldest First', value: SortOrder.ASC },
  ];
  filteredDateSort = model<{ label: string; value: SortOrder }>(this.sortDropdownOptions[0]);

  rowsPerPageOptions = [50, 100];
  tableRowLimit = this.rowsPerPageOptions[0];
  tableFirst = model(0);

  currentPage = 1;

  // computedFilteredDateRangeTime = computed<[Date, Date] | undefined>(() => {
  //   if (this.filteredDateRange()?.length == 2 && this.filteredDateRangeValid() && this.filteredTimeRangeValid()) {
  //     const [startDate, endDate] = this.filteredDateRange()!;
  //     const startDateTime = dayjsUtil(this.filteredDateRangeFirstTime());
  //     const endDateTime = dayjsUtil(this.filteredDateRangeSecondTime());
  //
  //     return [
  //       dayjsUtil(startDate).add(startDateTime.get('hour'), 'hour').add(startDateTime.get('minute'), 'minute').toDate(),
  //       dayjsUtil(endDate).add(endDateTime.get('hour'), 'hour').add(endDateTime.get('minute'), 'minute').toDate(),
  //     ];
  //   }
  //
  //   return;
  // });

  allFiltersActive = signal<ActiveFilter[]>([]);

  // filteredDateRangeValid = computed<boolean>(() => {
  //   const [startDate, endDate] = this.filteredDateRange() || [];
  //   if (startDate && endDate) {
  //     return dayjsUtil(startDate).isSameOrBefore(dayjsUtil(endDate), 'day');
  //   } else if (startDate && !endDate) {
  //     return false;
  //   } else if (!startDate && endDate) {
  //     return false;
  //   }
  //
  //   return true;
  // });
  //
  // filteredDurationRangeValid = computed<boolean>(() => {
  //   const startDuration = this.filteredDurationFirstTime();
  //   const endDuration = this.filteredDurationSecondTime();
  //   if (startDuration && endDuration) {
  //     return dayjsUtil(startDuration).isSameOrBefore(endDuration);
  //   }
  //
  //   return true;
  // });
  //
  // filteredTimeRangeValid = computed<boolean>(() => {
  //   const [startDate, endDate] = this.filteredDateRange() || [];
  //   if (this.filteredDateRangeFirstTime() && this.filteredDateRangeSecondTime()) {
  //     return dayjsUtil(this.filteredDateRangeFirstTime()).isSameOrBefore(this.filteredDateRangeSecondTime());
  //   } else if (this.filteredDateRangeFirstTime() && !this.filteredDateRangeSecondTime()) {
  //     return false;
  //   } else if (!this.filteredDateRangeFirstTime() && this.filteredDateRangeSecondTime()) {
  //     return false;
  //   } else if (startDate && endDate) {
  //     return !!this.filteredDateRangeFirstTime() && !!this.filteredDateRangeSecondTime();
  //   }
  //
  //   return true;
  // });

  // allFiltersValid = computed(() => {
  //   return this.filteredDateRangeValid() && this.filteredTimeRangeValid() && this.filteredDurationRangeValid();
  // });

  // salesMembers = signal<IGetCASalesMembersFiltersResponse>([]);
  // salesTeams = signal<IGetCASalesTeamsFiltersResponse>([]);
  //
  // statusesAvailable: WritableSignal<IGetCAStatusFiltersResponse> = signal([]);
  // departmentsAvailable: WritableSignal<IGetCADepartmentFiltersResponse> = signal([]);
  // callSourcesAvailable: WritableSignal<IGetCACallSourceFiltersResponse> = signal([]);

  dateFilterOptions: { label: string; value: [Date, Date] }[] = [
    { label: 'Today', value: [dayjsUtil().startOf('day').toDate(), dayjsUtil().startOf('day').toDate()] },
    {
      label: 'Yesterday',
      value: [
        dayjsUtil().subtract(1, 'day').startOf('day').toDate(),
        dayjsUtil().subtract(1, 'day').startOf('day').toDate(),
      ],
    },
    {
      label: 'Last 7 days',
      value: [dayjsUtil().subtract(7, 'day').startOf('day').toDate(), dayjsUtil().startOf('day').toDate()],
    },
    {
      label: 'Last 30 days',
      value: [dayjsUtil().subtract(30, 'day').startOf('day').toDate(), dayjsUtil().startOf('day').toDate()],
    },
  ];

  calendarMaxDate = signal(new Date());
  durationStartDefaultDate = signal(dayjsUtil().startOf('day').toDate());
  durationEndDefaultDate = signal(dayjsUtil().startOf('day').add(1, 'hour').toDate());

  tableColumns = signal<Column[]>([
    { identifier: 'deal_id', field: 'deal_id', header: 'Deal ID' },
    { identifier: 'deal_health', field: 'deal_health', header: 'Deal health' },
    { identifier: 'deal_status', field: 'deal_status', header: 'Deal status' },
    { identifier: 'expected_value', field: 'expected_value', header: 'Expected value' },
    { identifier: 'deal_age', field: 'deal_age', header: 'Deal age' },
    { identifier: 'heat_map', field: 'heat_map', header: 'Heat-map' },
    { identifier: 'account', field: 'account', header: 'Account' },
    { identifier: 'member', field: 'member', header: 'Member' },
    { identifier: 'conversion', field: 'conversion', header: 'Conversion' },
    { identifier: 'closing_date', field: 'closing_date', header: 'Closing date' },
    { identifier: 'action', field: '', header: '' },
  ]);

  enabledColumns = computed(() => {
    return this.tableColumns().filter((item) => !item.optional || (item.optional && item.visible));
  });
  filterCards = signal<IFilterCard[]>([
    {
      label: 'All deals',
      count: 750,
      value: 3957400,
    },
    {
      label: 'On hold',
      count: 50,
      value: 275500,
    },
    {
      label: 'Communication',
      count: 200,
      value: 670500,
    },
    {
      label: 'Demo & presentation',
      count: 100,
      value: 550700,
    },
    {
      label: 'Proposal',
      count: 150,
      value: 650700,
    },
    {
      label: 'Contract signed',
      count: 300,
      value: 1600900,
    },
    {
      label: 'Deal lost',
      count: 50,
      value: 261500,
    },
  ]);

  chartData = input<ChartConfiguration['data']>({
    labels: ['Sat', 'Sun', 'Mon', 'Tue', 'Wed', 'Thurs', 'Fri'],
    datasets: [
      {
        label: 'Call Duration Average',
        data: [50, 40, 100, 70, 20, 30, 80],
        backgroundColor: '#a257a2',
        borderColor: '#a257a2',
      },
      {
        label: 'Chat Duration Average',
        data: [50, 40, 100, 70, 20, 30, 80].reverse(),
        backgroundColor: '#15888a',
        borderColor: '#15888a',
      },
    ],
  });
  chartOptions = input<ChartConfiguration['options']>({
    responsive: true,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  });

  constructor(
    private salesBeApiService: SalesBeApiService,
    private router: Router,
    private route: ActivatedRoute,
    private globalStateService: GlobalStateService,
  ) {}

  ngOnInit() {
    this.updateHeaderDetails();

    // this.updateModelsFromUrlParams();
    // this.updateActiveFilterList();
    //
    // this.salesBeApiService.getCallAnalysisStatusFilters().subscribe((response) => {
    //   this.statusesAvailable.set(response);
    // });
    //
    // this.salesBeApiService.getCallAnalysisSalesMembers().subscribe((response) => {
    //   this.salesMembers.set(response);
    // });
    //
    // this.salesBeApiService.getCallAnalysisSalesTeams().subscribe((response) => {
    //   this.salesTeams.set(response);
    // });
    //
    // this.salesBeApiService.getCallSources().subscribe((response) => {
    //   this.callSourcesAvailable.set(response);
    // });
    //
    // this.salesBeApiService.getCallAnalysisDepartments().subscribe((response) => {
    //   this.departmentsAvailable.set(response);
    // });
  }

  protected isEnabledColumn(identifier: string) {
    return this.enabledColumns().findIndex((item) => item.identifier === identifier) > -1;
  }

  private updateHeaderDetails() {
    this.globalStateService.headerDetails.set({
      title: 'New Deals',
    });
  }

  // private fetchCallAnalysisRecords() {
  //   this.isTableLoading.set(true);
  //   this.salesBeApiService
  //     .getCallAnalysisRecords(
  //       {
  //         pageNumber: this.currentPage,
  //         pageLimit: this.tableRowLimit,
  //         sortOrder: this.filteredDateSort().value,
  //       },
  //       {
  //         agentName: this.filteredSalesMember(),
  //         teamName: this.filteredSalesTeam(),
  //         dateFrom: this.computedFilteredDateRangeTime()?.[0],
  //         dateTo: this.computedFilteredDateRangeTime()?.[1],
  //         status: this.filteredStatus(),
  //         department: this.filteredDepartment(),
  //         account_phone: this.filteredAccountPhone(),
  //         accountId: this.filteredAccountId(),
  //         leadId: this.filteredLeadId(),
  //         dealId: this.filteredDealId(),
  //         callDurationFilterFrom: this.filteredDurationFirstTime()
  //           ? dayjsUtil(this.filteredDurationFirstTime()).diff(
  //             dayjsUtil(this.filteredDurationFirstTime()).startOf('day'),
  //             'second',
  //           )
  //           : undefined,
  //         callDurationFilterTo: this.filteredDurationSecondTime()
  //           ? dayjsUtil(this.filteredDurationSecondTime()).diff(
  //             dayjsUtil(this.filteredDurationSecondTime()).startOf('day'),
  //             'second',
  //           )
  //           : undefined,
  //         callSource: this.filteredCallSources(),
  //       },
  //     )
  //     .pipe(finalize(() => this.isTableLoading.set(false)))
  //     .subscribe((response) => {
  //       this.callAnalysisRecords.set(response);
  //     });
  // }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onPageChange(event: any) {
    this.currentPage = Math.ceil(event.first / event.rows) + 1;
    this.tableRowLimit = event.rows;
    // this.updateUrlParams();
    // this.fetchCallAnalysisRecords();
  }

  onFilterButtonClicked() {
    this.sideFilterBarVisible.update((value) => !value);
  }

  onColumnsButtonClicked() {
    this.columnsBarVisible.update((value) => !value);
  }

  onColumnsButtonClose() {
    this.columnsBarVisible.set(false);
    // this.updateUrlParams();
  }

  toggleVisibility(column: Column, isVisible: boolean): void {
    column.visible = isVisible;
    this.tableColumns.set([...this.tableColumns()]);
  }

  private fetchCallAnalysisRecordsFromFirstPage() {
    this.tableFirst.set(0);
    this.currentPage = 1;
    // this.fetchCallAnalysisRecords();
  }

  onFilterApply() {
    this.sideFilterBarVisible.set(false);
    // this.updateActiveFilterList();
    this.fetchCallAnalysisRecordsFromFirstPage();
    // this.updateUrlParams();
  }

  onFilterCancel() {
    this.sideFilterBarVisible.set(false);
    // this.updateModelsFromUrlParams(true);
  }

  onFilterAllClear() {
    // this.filteredSalesMember.set(undefined);
    // this.filteredSalesTeam.set(undefined);
    // this.filteredStatus.set(undefined);
    // this.filteredDateRange.set(undefined);
    // this.filteredDateRangeFirstTime.set(undefined);
    // this.filteredDateRangeSecondTime.set(undefined);
    // this.filteredDurationFirstTime.set(undefined);
    // this.filteredDurationSecondTime.set(undefined);
    // this.filteredAccountId.set(undefined);
    // this.filteredLeadId.set(undefined);
    // this.filteredDealId.set(undefined);
    // this.filteredListen.set(undefined);
    // this.filteredDepartment.set(undefined);
    // this.filteredAccountPhone.set(undefined);
    // this.filteredCallSources.set(undefined);
    // this.updateActiveFilterList();
    this.fetchCallAnalysisRecordsFromFirstPage();
    // this.updateUrlParams();
  }

  // onFilteredDateRangeChanged(data: [Date, Date]) {
  //   if (data && data[0]) {
  //     this.filteredDateRangeFirstTime.set(dayjsUtil(data[0]).startOf('day').toDate());
  //   } else {
  //     this.filteredDateRangeFirstTime.set(undefined);
  //   }
  //   if (data && data[1]) {
  //     this.filteredDateRangeSecondTime.set(
  //       dayjsUtil(data[1]).isSame(dayjsUtil(), 'day')
  //         ? new Date()
  //         : dayjsUtil(data[1]).endOf('day').endOf('hour').endOf('minute').toDate(),
  //     );
  //   } else {
  //     this.filteredDateRangeSecondTime.set(undefined);
  //   }
  // }

  onFilterChipRemoved(filter: ActiveFilter) {
    filter.models.forEach((mdl) => mdl.set(undefined));
    // this.updateActiveFilterList();
    this.fetchCallAnalysisRecordsFromFirstPage();
    // this.updateUrlParams();
  }

  // onQuickView(record: IGetCATableRecord) {
  //   this.quickViewModalData.set(undefined);
  //   this.quickViewModalVisible.set(true);
  //   this.salesBeApiService.getCallAnalysisQuickView(record.call_id).subscribe((response) => {
  //     this.quickViewModalData.set(response);
  //   });
  // }

  // private updateUrlParams() {
  //   const queryParams: UrlQueryParams = {
  //     sortOrder: this.filteredDateSort().value,
  //     tableRowLimit: this.tableRowLimit.toString(),
  //     page: this.currentPage.toString(),
  //   };
  //
  //   if (this.filteredSalesMember()) {
  //     queryParams['salesMember'] = this.filteredSalesMember();
  //   }
  //   if (this.filteredSalesTeam()) {
  //     queryParams['salesTeam'] = this.filteredSalesTeam();
  //   }
  //   if (this.computedFilteredDateRangeTime()?.[0]) {
  //     queryParams['dateFrom'] = dayjsUtil(this.computedFilteredDateRangeTime()![0]).format('YYYY-MM-DDTHH:mm:ss');
  //   }
  //   if (this.computedFilteredDateRangeTime()?.[1]) {
  //     queryParams['dateTo'] = dayjsUtil(this.computedFilteredDateRangeTime()![1]).format('YYYY-MM-DDTHH:mm:ss');
  //   }
  //   if (this.filteredStatus()) {
  //     queryParams['status'] = this.filteredStatus();
  //   }
  //   if (this.filteredDurationFirstTime()) {
  //     queryParams['durationFrom'] = dayjsUtil(this.filteredDurationFirstTime()).format('HH:mm:ss');
  //   }
  //   if (this.filteredDurationSecondTime()) {
  //     queryParams['durationTo'] = dayjsUtil(this.filteredDurationSecondTime()).format('HH:mm:ss');
  //   }
  //   if (this.filteredAccountId()) {
  //     queryParams['accountId'] = this.filteredAccountId();
  //   }
  //   if (this.filteredLeadId()) {
  //     queryParams['leadId'] = this.filteredLeadId();
  //   }
  //   if (this.filteredDealId()) {
  //     queryParams['dealId'] = this.filteredDealId();
  //   }
  //   if (this.filteredListen()?.length) {
  //     queryParams['listen'] = this.filteredListen();
  //   }
  //   if (this.filteredDepartment()) {
  //     queryParams['department'] = this.filteredDepartment();
  //   }
  //   if (this.filteredAccountPhone()) {
  //     queryParams['account_phone'] = this.filteredAccountPhone();
  //   }
  //   if (this.filteredCallSources()) {
  //     queryParams['callSource'] = this.filteredCallSources();
  //   }
  //
  //   const visibleColumns = this.tableColumns().filter((item) => !!item.visible);
  //   if (visibleColumns.length > 0) {
  //     queryParams['visibleColumns'] = visibleColumns.map((item) => item.identifier);
  //   }
  //
  //   this.router.navigate([], {
  //     relativeTo: this.route,
  //     queryParams,
  //   });
  // }

  // private updateModelsFromUrlParams(resetFilters = false) {
  //   const queryParams = this.route.snapshot.queryParams as UrlQueryParams;
  //   if (queryParams['sortOrder']) {
  //     this.filteredDateSort.set(
  //       this.sortDropdownOptions.find((option) => option.value === queryParams['sortOrder']) ||
  //       this.sortDropdownOptions[0],
  //     );
  //   }
  //
  //   if (queryParams['visibleColumns']) {
  //     const columns = [...this.tableColumns()];
  //     const visibleColumns = Array.isArray(queryParams['visibleColumns'])
  //       ? queryParams['visibleColumns']
  //       : [queryParams['visibleColumns']];
  //     visibleColumns.forEach((column: string) => {
  //       const index = columns.findIndex((item) => item.identifier === column);
  //       if (index !== -1) {
  //         columns[index].visible = true;
  //       }
  //     });
  //     this.tableColumns.set([...columns]);
  //   }
  //
  //   if (queryParams['tableRowLimit']) {
  //     this.tableRowLimit = Number(queryParams['tableRowLimit']);
  //   }
  //   if (queryParams['page']) {
  //     this.currentPage = Number(queryParams['page']);
  //     this.tableFirst.set((this.currentPage - 1) * this.tableRowLimit);
  //   }
  //
  //   if (queryParams['salesMember']) {
  //     this.filteredSalesMember.set(
  //       Array.isArray(queryParams['salesMember']) ? queryParams['salesMember'] : [queryParams['salesMember']],
  //     );
  //   } else if (resetFilters && !queryParams['salesMember']) {
  //     this.filteredSalesMember.set(undefined);
  //   }
  //   if (queryParams['salesTeam']) {
  //     this.filteredSalesTeam.set(
  //       Array.isArray(queryParams['salesTeam']) ? queryParams['salesTeam'] : [queryParams['salesTeam']],
  //     );
  //   } else if (resetFilters && !queryParams['salesTeam']) {
  //     this.filteredSalesTeam.set(undefined);
  //   }
  //   if (queryParams['dateFrom'] && queryParams['dateTo']) {
  //     const dateFrom = dayjsUtil(queryParams['dateFrom']);
  //     const dateTo = dayjsUtil(queryParams['dateTo']);
  //     this.filteredDateRange.set([dateFrom.startOf('day').toDate(), dateTo.startOf('day').toDate()]);
  //     this.filteredDateRangeFirstTime.set(
  //       dayjsUtil(dateFrom)
  //         .startOf('day')
  //         .add(dateFrom.get('hour'), 'hour')
  //         .add(dateFrom.get('minute'), 'minute')
  //         .toDate(),
  //     );
  //     this.filteredDateRangeSecondTime.set(
  //       dayjsUtil(dateTo).startOf('day').add(dateTo.get('hour'), 'hour').add(dateTo.get('minute'), 'minute').toDate(),
  //     );
  //   } else if (resetFilters && !(queryParams['dateFrom'] && queryParams['dateTo'])) {
  //     this.filteredDateRange.set(undefined);
  //     this.filteredDateRangeFirstTime.set(undefined);
  //     this.filteredDateRangeSecondTime.set(undefined);
  //   }
  //   if (queryParams['status']) {
  //     this.filteredStatus.set(Array.isArray(queryParams['status']) ? queryParams['status'] : [queryParams['status']]);
  //   } else if (resetFilters && !queryParams['status']) {
  //     this.filteredStatus.set(undefined);
  //   }
  //   if (queryParams['durationFrom']) {
  //     const [hour, minute, second] = queryParams['durationFrom'].split(':');
  //     this.filteredDurationFirstTime.set(
  //       dayjsUtil()
  //         .startOf('day')
  //         .add(Number(hour), 'hour')
  //         .add(Number(minute), 'minute')
  //         .add(Number(second), 'second')
  //         .toDate(),
  //     );
  //   } else if (resetFilters && !queryParams['durationFrom']) {
  //     this.filteredDurationFirstTime.set(undefined);
  //   }
  //   if (queryParams['durationTo']) {
  //     const [hour, minute, second] = queryParams['durationTo'].split(':');
  //     this.filteredDurationSecondTime.set(
  //       dayjsUtil()
  //         .startOf('day')
  //         .add(Number(hour), 'hour')
  //         .add(Number(minute), 'minute')
  //         .add(Number(second), 'second')
  //         .toDate(),
  //     );
  //   } else if (resetFilters && !queryParams['durationTo']) {
  //     this.filteredDurationSecondTime.set(undefined);
  //   }
  //   if (queryParams['accountId']) {
  //     this.filteredAccountId.set(queryParams['accountId']);
  //   } else if (resetFilters && !queryParams['accountId']) {
  //     this.filteredAccountId.set(undefined);
  //   }
  //   if (queryParams['leadId']) {
  //     this.filteredLeadId.set(queryParams['leadId']);
  //   } else if (resetFilters && !queryParams['leadId']) {
  //     this.filteredLeadId.set(undefined);
  //   }
  //   if (queryParams['dealId']) {
  //     this.filteredDealId.set(queryParams['dealId']);
  //   } else if (resetFilters && !queryParams['dealId']) {
  //     this.filteredDealId.set(undefined);
  //   }
  //   if (queryParams['listen']) {
  //     this.filteredListen.set(queryParams['listen']);
  //   } else if (resetFilters && !queryParams['listen']) {
  //     this.filteredListen.set([]);
  //   }
  //   if (queryParams['department']) {
  //     this.filteredDepartment.set(
  //       Array.isArray(queryParams['department']) ? queryParams['department'] : [queryParams['department']],
  //     );
  //   } else if (resetFilters && !queryParams['department']) {
  //     this.filteredDepartment.set(undefined);
  //   }
  //   if (queryParams['account_phone']) {
  //     this.filteredAccountPhone.set(queryParams['account_phone']);
  //   } else if (resetFilters && !queryParams['account_phone']) {
  //     this.filteredAccountPhone.set(undefined);
  //   }
  //   if (queryParams['callSource']) {
  //     this.filteredCallSources.set(
  //       Array.isArray(queryParams['callSource']) ? queryParams['callSource'] : [queryParams['callSource']],
  //     );
  //   } else if (resetFilters && !queryParams['callSource']) {
  //     this.filteredCallSources.set(undefined);
  //   }
  // }

  // private updateActiveFilterList() {
  //   const activeFilters: ActiveFilter[] = [];
  //   if (this.filteredSalesMember()?.length) {
  //     activeFilters.push({
  //       label: `Sales Members: ${this.filteredSalesMember()!.join(', ')}`,
  //       models: [this.filteredSalesMember],
  //     });
  //   }
  //   if (this.filteredSalesTeam()?.length) {
  //     activeFilters.push({
  //       label: `Sales Teams: ${this.filteredSalesTeam()!.join(', ')}`,
  //       models: [this.filteredSalesTeam],
  //     });
  //   }
  //   if (this.filteredStatus()?.length) {
  //     activeFilters.push({
  //       label: `Statuses: ${this.filteredStatus()!.join(', ')}`,
  //       models: [this.filteredStatus],
  //     });
  //   }
  //   if (this.computedFilteredDateRangeTime()) {
  //     const [startDate, endDate] = this.computedFilteredDateRangeTime()!;
  //
  //     activeFilters.push({
  //       label: `Date Range: ${dayjsUtil(startDate).format('YYYY-MM-DD HH:mm')} - ${dayjsUtil(endDate).format('YYYY-MM-DD HH:mm')}`,
  //       models: [this.filteredDateRange],
  //     });
  //   }
  //   if (this.filteredDurationFirstTime() || this.filteredDurationSecondTime()) {
  //     let durationStart = '',
  //       durationEnd = '';
  //     if (this.filteredDurationFirstTime()) {
  //       const d = dayjsUtil(this.filteredDurationFirstTime());
  //       durationStart = `${d.format('H')}h:${d.format('m')}m:${d.format('s')}s`;
  //     } else {
  //       durationStart = '--h:--m:--s';
  //     }
  //     if (this.filteredDurationSecondTime()) {
  //       const d = dayjsUtil(this.filteredDurationSecondTime());
  //       durationEnd = `${d.format('H')}h:${d.format('m')}m:${d.format('s')}s`;
  //     } else {
  //       durationEnd = '--h:--m:--s';
  //     }
  //     activeFilters.push({
  //       label: `Duration: ${durationStart} to ${durationEnd}`,
  //       models: [this.filteredDurationFirstTime, this.filteredDurationSecondTime],
  //     });
  //   }
  //   if (this.filteredAccountId()) {
  //     activeFilters.push({
  //       label: `Account ID: ${this.filteredAccountId()}`,
  //       models: [this.filteredAccountId],
  //     });
  //   }
  //   if (this.filteredLeadId()) {
  //     activeFilters.push({
  //       label: `Lead ID: ${this.filteredLeadId()}`,
  //       models: [this.filteredLeadId],
  //     });
  //   }
  //   if (this.filteredDealId()) {
  //     activeFilters.push({
  //       label: `Deal ID: ${this.filteredDealId()}`,
  //       models: [this.filteredDealId],
  //     });
  //   }
  //   if (this.filteredListen()?.length) {
  //     activeFilters.push({
  //       label: `Listen: ${this.filteredListen()!.join(', ')}`,
  //       models: [this.filteredListen],
  //     });
  //   }
  //   if (this.filteredDepartment()?.length) {
  //     activeFilters.push({
  //       label: `Departments: ${this.filteredDepartment()!.join(', ')}`,
  //       models: [this.filteredDepartment],
  //     });
  //   }
  //   if (this.filteredAccountPhone()) {
  //     activeFilters.push({
  //       label: `Account Phone: ${this.filteredAccountPhone()}`,
  //       models: [this.filteredAccountPhone],
  //     });
  //   }
  //   if (this.filteredCallSources()?.length) {
  //     activeFilters.push({
  //       label: `Call Source: ${this.filteredCallSources()!.join(', ')}`,
  //       models: [this.filteredCallSources],
  //     });
  //   }
  //
  //   this.allFiltersActive.set(activeFilters);
  // }

  protected readonly Array = Array;
  protected readonly NEW_DEAL_PATH = NEW_DEAL_PATH;
  protected readonly CRM_BASE_URL = environment.zohoCrmBaseUrl;
}
