<div class="flex flex-row flex-wrap mb-3 call-analysis-list-page with-side-filter-drawer">
  <div class="flex flex-row items-center gap-4">
    <div>
      <p-button
        (click)="onFilterButtonClicked()"
        [badge]="allFiltersActive().length ? allFiltersActive().length.toString() : ''"
        badgeClass="side-filter-list-p-badge-contrast"
        icon="pi pi-filter"
        label="Filter"
        outlined="true"
      />
      @if (allFiltersActive().length) {
        <p-button
          icon="pi pi-times"
          text="true"
          styleClass="side-filter-clear-button-main"
          pTooltip="Clear All"
          tooltipPosition="bottom"
          (click)="onFilterAllClear()"
        />
      }
    </div>
    <p-button
      (click)="onColumnsButtonClicked()"
      badgeClass="side-filter-list-p-badge-contrast"
      icon="fa fa-columns-3"
      label="Columns"
      outlined="true"
    />

    @for (filter of allFiltersActive(); track filter.label) {
      <div>
        <p-chip
          [label]="filter.label"
          [removable]="true"
          (onRemove)="onFilterChipRemoved(filter)"
        />
      </div>
    }
  </div>

  <div class="ml-auto flex flex-row items-center">
    <p-dropdown
      (ngModelChange)="onFilterApply()"
      [(ngModel)]="filteredDateSort"
      [options]="sortDropdownOptions"
      checkmark="true"
      class="sort-dropdown"
      optionLabel="label"
      placeholder="Sort by Date"
    ></p-dropdown>
  </div>
</div>

<p-sidebar
  [(visible)]="sideFilterBarVisible"
  [style]="{ width: '480px' }"
  appendTo="body"
  closeOnEscape="false"
  dismissible="false"
  position="right"
  showCloseIcon="false"
  styleClass="side-filter-drawer"
>
  <ng-template pTemplate="header">
    <div class="header-container">
      <span class="text-xl">Filters</span>
      <p-button
        (onClick)="onFilterCancel()"
        icon="pi pi-times"
        rounded="true"
        text="true"
      />
    </div>
  </ng-template>

  <p-divider />

  @if (sideFilterBarVisible()) {
    <p-accordion
      multiple="true"
      expandIcon="pi pi-angle-down"
      collapseIcon="pi pi-angle-up"
    >
      <p-accordionTab
        header="Agent"
        iconPos="end"
        transitionOptions="200ms cubic-bezier(0.86, 0, 0.07, 1)"
      >
        <p-multiSelect
          [options]="salesMembers()"
          [(ngModel)]="filteredSalesMember"
          showClear="true"
          filter="true"
          showToggleAll="false"
          [maxSelectedLabels]="1"
          placeholder="Select agents"
          appendTo="body"
        />
      </p-accordionTab>
      <p-accordionTab
        header="Agent Team"
        iconPos="end"
        transitionOptions="200ms cubic-bezier(0.86, 0, 0.07, 1)"
      >
        <p-multiSelect
          [options]="salesTeams()"
          [(ngModel)]="filteredSalesTeam"
          showClear="true"
          filter="true"
          showToggleAll="false"
          [maxSelectedLabels]="1"
          placeholder="Select agent teams"
          appendTo="body"
        />
      </p-accordionTab>
      <p-accordionTab
        header="Department"
        iconPos="end"
        transitionOptions="200ms cubic-bezier(0.86, 0, 0.07, 1)"
      >
        <p-multiSelect
          [options]="departmentsAvailable()"
          [(ngModel)]="filteredDepartment"
          showClear="true"
          filter="true"
          showToggleAll="false"
          [maxSelectedLabels]="1"
          placeholder="Select departments"
          appendTo="body"
        />
      </p-accordionTab>
      <p-accordionTab
        header="Call Source"
        iconPos="end"
        transitionOptions="200ms cubic-bezier(0.86, 0, 0.07, 1)"
      >
        <p-multiSelect
          [options]="callSourcesAvailable()"
          [(ngModel)]="filteredCallSources"
          showClear="true"
          filter="true"
          showToggleAll="false"
          [maxSelectedLabels]="1"
          placeholder="Select call sources"
          appendTo="body"
        />
      </p-accordionTab>
      <p-accordionTab
        header="Call Date and time"
        iconPos="end"
        transitionOptions="200ms cubic-bezier(0.86, 0, 0.07, 1)"
      >
        <div class="flex flex-column">
          <p-selectButton
            [options]="dateFilterOptions"
            [(ngModel)]="filteredDateRange"
            (ngModelChange)="onFilteredDateRangeChanged($event)"
            optionLabel="label"
            optionValue="value"
            styleClass="flex flex-column"
          />
          <div class="flex mb-2">
            <p-calendar
              [(ngModel)]="filteredDateRange"
              (ngModelChange)="onFilteredDateRangeChanged($event)"
              selectionMode="range"
              [readonlyInput]="true"
              [numberOfMonths]="1"
              [maxDate]="calendarMaxDate()"
              appendTo="body"
              hideOnDateTimeSelect="true"
              [firstDayOfWeek]="0"
              placeholder="Specific Range"
              class="side-filter-drawer-date-range"
              showButtonBar="true"
              [styleClass]="filteredDateRangeValid() ? '' : 'side-filter-drawer-invalid-time-range'"
            />
          </div>

          <div class="flex flex-row">
            <p-calendar
              [timeOnly]="true"
              hourFormat="12"
              [(ngModel)]="filteredDateRangeFirstTime"
              placeholder="Call Start Date Time"
              [styleClass]="filteredTimeRangeValid() ? '' : 'side-filter-drawer-invalid-time-range'"
            />
            <div class="mt-2 mr-2 ml-2">to</div>
            <p-calendar
              [timeOnly]="true"
              hourFormat="12"
              [(ngModel)]="filteredDateRangeSecondTime"
              placeholder="Call End Date Time"
              [styleClass]="filteredTimeRangeValid() ? '' : 'side-filter-drawer-invalid-time-range'"
            />
          </div>
        </div>
      </p-accordionTab>
      <p-accordionTab
        header="Call Duration"
        iconPos="end"
        transitionOptions="200ms cubic-bezier(0.86, 0, 0.07, 1)"
      >
        <div class="flex flex-row">
          <p-calendar
            [timeOnly]="true"
            hourFormat="24"
            showSeconds="true"
            [(ngModel)]="filteredDurationFirstTime"
            placeholder="Call Duration Start (hh:mm:ss)"
            [defaultDate]="durationStartDefaultDate()"
            [styleClass]="filteredDurationRangeValid() ? '' : 'side-filter-drawer-invalid-time-range'"
          />
          <div class="mt-2 mr-2 ml-2">to</div>
          <p-calendar
            [timeOnly]="true"
            hourFormat="24"
            showSeconds="true"
            [(ngModel)]="filteredDurationSecondTime"
            placeholder="Call Duration End (hh:mm:ss)"
            [defaultDate]="durationEndDefaultDate()"
            [styleClass]="filteredDurationRangeValid() ? '' : 'side-filter-drawer-invalid-time-range'"
          />
        </div>
      </p-accordionTab>
      <p-accordionTab
        header="Contract Status"
        iconPos="end"
        transitionOptions="200ms cubic-bezier(0.86, 0, 0.07, 1)"
      >
        <p-multiSelect
          [options]="statusesAvailable()"
          [(ngModel)]="filteredStatus"
          showClear="true"
          filter="true"
          showToggleAll="false"
          [maxSelectedLabels]="1"
          placeholder="Select contract statuses"
          appendTo="body"
        />
      </p-accordionTab>
      <p-accordionTab
        header="Account Phone"
        iconPos="end"
        transitionOptions="200ms cubic-bezier(0.86, 0, 0.07, 1)"
      >
        <p-inputMask
          [(ngModel)]="filteredAccountPhone"
          maxlength="10"
          minlength="3"
          placeholder="Enter the phone number in the format 05xxxxxxxx"
          mask="999?9999999"
        />
      </p-accordionTab>

      <p-accordionTab
        header="Account ID"
        iconPos="end"
        transitionOptions="200ms cubic-bezier(0.86, 0, 0.07, 1)"
      >
        <input
          type="text"
          pInputText
          [(ngModel)]="filteredAccountId"
          placeholder="Enter Account ID"
        />
      </p-accordionTab>
      <p-accordionTab
        header="Lead ID"
        iconPos="end"
        transitionOptions="200ms cubic-bezier(0.86, 0, 0.07, 1)"
      >
        <input
          type="text"
          pInputText
          [(ngModel)]="filteredLeadId"
          placeholder="Enter Lead ID"
        />
      </p-accordionTab>
      <p-accordionTab
        header="Deal ID"
        iconPos="end"
        transitionOptions="200ms cubic-bezier(0.86, 0, 0.07, 1)"
      >
        <input
          type="text"
          pInputText
          [(ngModel)]="filteredDealId"
          placeholder="Enter Deal ID"
        />
      </p-accordionTab>
    </p-accordion>

    <div class="footer-container">
      <p-divider></p-divider>
      <div class="button-container">
        <p-button
          label="Cancel"
          (onClick)="onFilterCancel()"
          class="apply-button"
        ></p-button>
        <p-button
          label="Clear All"
          class="apply-button"
          (onClick)="onFilterAllClear()"
        />
        <p-button
          label="Apply"
          [disabled]="!allFiltersValid()"
          (onClick)="onFilterApply()"
          class="apply-button"
        ></p-button>
      </div>
    </div>
  }
</p-sidebar>

<p-sidebar
  [(visible)]="columnsBarVisible"
  [style]="{ width: '17rem' }"
  appendTo="body"
  closeOnEscape="true"
  dismissible="true"
  position="right"
  showCloseIcon="false"
  styleClass="side-filter-drawer"
>
  <ng-template pTemplate="header">
    <div class="header-container">
      <span class="text-xl">Enabled Columns</span>
      <p-button
        (onClick)="onColumnsButtonClose()"
        icon="pi pi-times"
        rounded="true"
        text="true"
      />
    </div>
  </ng-template>

  <p-divider />

  @for (column of tableColumns(); track column.identifier) {
    @if (column.optional) {
      <div class="mb-2">
        <label class="text-sm">
          <input
            type="checkbox"
            [checked]="column.visible"
            (change)="toggleVisibility(column, !column.visible)"
          />
          {{ column.header }}
        </label>
      </div>
    }
  }
</p-sidebar>

<div class="rwa__datatable fit-page flex flex-column">
  <p-table
    [scrollable]="true"
    (onLazyLoad)="onPageChange($event)"
    [(first)]="tableFirst"
    [columns]="enabledColumns()"
    [rowsPerPageOptions]="rowsPerPageOptions"
    [rows]="tableRowLimit"
    [showCurrentPageReport]="true"
    [totalRecords]="callAnalysisRecords().total_records"
    [value]="callAnalysisRecords().records"
    lazy="true"
    paginator="true"
  >
    <ng-template
      let-columns
      pTemplate="header"
    >
      <tr class="table-header">
        @for (col of columns; track col.header; let last = $last) {
          @if (last) {
            <th
              pFrozenColumn
              [frozen]="true"
              alignFrozen="right"
              class="last-frozen-column"
              [ngClass]="{ 'table-head-center': last }"
            >
              {{ col.header }}
            </th>
          } @else {
            <th [ngClass]="{ 'table-head-center': last }">
              {{ col.header }}
            </th>
          }
        }
      </tr>
    </ng-template>

    <ng-template
      let-record
      pTemplate="body"
    >
      <tr>
        @if (isTableLoading()) {
          @for (col of enabledColumns(); track col.header; let even = $even) {
            <td>
              <p-skeleton [style]="{ width: even ? '65%' : '40%' }" />
            </td>
          }
        } @else {
          @let durationArr = record.duration.split(':');
          @if (isEnabledColumn('call_id')) {
            <td>
              <div style="width: 150px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis">
                <swa-listing-anchor
                  [link]="CALLS_ANALYSIS_PATH + '/' + record.call_id"
                  [displayText]="record.call_id"
                />
              </div>
            </td>
          }
          @if (isEnabledColumn('agent_name')) {
            <td>
              <div style="max-width: 200px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis">
                {{ record.agent_name }}
              </div>
              <div class="record-sub-text">{{ record.member_team }}</div>
            </td>
          }
          @if (isEnabledColumn('account_name')) {
            <td>
              <div style="max-width: 200px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis">
                <swa-cropped-text [text]="record.account_name"></swa-cropped-text>
              </div>
              <div class="record-sub-text">{{ record.account_phone }}</div>
            </td>
          }
          @if (isEnabledColumn('datetime')) {
            <td>
              <div>
                {{ record.datetime | date: 'hh:mm a' }}
              </div>
              <div>
                {{ record.datetime | date: 'dd MMM YYYY' }}
              </div>
            </td>
          }
          @if (isEnabledColumn('duration')) {
            <td>
              <div>
                {{ +durationArr[0] + 'h:' + +durationArr[1] + 'm:' + +durationArr[2].split('.')[0] + 's' }}
              </div>
            </td>
          }
          @if (isEnabledColumn('status')) {
            <td>
              <p-chip [label]="record.status"></p-chip>
            </td>
          }
          @if (isEnabledColumn('account_id')) {
            <td>
              <div>
                <swa-listing-anchor
                  [link]="CRM_BASE_URL + '/tab/Accounts/' + record.account_id"
                  [displayText]="record.account_id"
                  [isExternal]="true"
                />
              </div>
            </td>
          }
          @if (isEnabledColumn('deal_id')) {
            <td>
              <div>
                <swa-listing-anchor
                  [link]="CRM_BASE_URL + '/tab/Potentials/' + record.deal_id"
                  [displayText]="record.deal_id"
                  [isExternal]="true"
                />
              </div>
            </td>
          }
          @if (isEnabledColumn('lead_id')) {
            <td>
              <div>
                <swa-listing-anchor
                  [link]="CRM_BASE_URL + '/tab/Leads/' + record.lead_id"
                  [displayText]="record.lead_id"
                  [isExternal]="true"
                />
              </div>
            </td>
          }
          @if (isEnabledColumn('call_source')) {
            <td>
              <div>
                {{ record.call_source }}
              </div>
            </td>
          }
          @if (isEnabledColumn('view_details')) {
            <td
              pFrozenColumn
              [frozen]="true"
              class="last-frozen-column"
              alignFrozen="right"
            >
              <div class="flex justify-content-center">
                <p-button
                  icon="pi pi-eye"
                  rounded="true"
                  text="true"
                  (onClick)="onQuickView(record)"
                />
              </div>
            </td>
          }
        }
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      @if (isTableLoading()) {
        @for (item of Array.from(Array(tableRowLimit).keys()); track item) {
          <tr>
            @for (col of enabledColumns(); track col.header; let even = $even) {
              <td>
                <p-skeleton [style]="{ width: even ? '65%' : '40%' }" />
              </td>
            }
          </tr>
        }
      }
    </ng-template>
  </p-table>
</div>

<p-dialog
  [(visible)]="quickViewModalVisible"
  showHeader="true"
  resizable="false"
  draggable="false"
  dismissableMask="true"
  [modal]="true"
  styleClass="calls-quick-view-modal"
>
  @let modalData = quickViewModalData();

  <ng-template pTemplate="header">
    <div></div>
  </ng-template>

  @if (modalData) {
    <div class="calls-quick-view-card">
      <div class="calls-quick-view-card-sidebar">
        <div class="calls-quick-view-card-sidebar-content">
          <div class="call-header">
            <div class="call-id">Call Id: {{ modalData?.call_id }}</div>
            @if (modalData?.status) {
              <div>
                <p-chip [label]="modalData!.status!" />
              </div>
            }
          </div>
          <div class="info-list">
            <div class="info-item">
              <div class="info-label">
                <i class="fa-regular fa-calendar"></i>
                <div class="label-text">Date</div>
              </div>
              <div class="value-text">{{ modalData?.datetime | date: 'dd MMM YYYY' }}</div>
            </div>
            <div class="divider"></div>
            <div class="info-item">
              <div class="info-label">
                <i class="fa-regular fa-user"></i>
                <div class="label-text">Agent</div>
              </div>
              <div class="value-text">{{ modalData?.agent_name || '?' }}</div>
            </div>
            <div class="divider"></div>
            <div class="info-item">
              <div class="info-label">
                <i class="fa-regular fa-file-user"></i>
                <div class="label-text">Account</div>
              </div>
              <div class="value-text">{{ modalData?.customer_name || '?' }}</div>
            </div>
            <div class="divider"></div>
          </div>
        </div>
        <p-button
          label="View full details"
          [routerLink]="CALLS_ANALYSIS_PATH + '/' + modalData.call_id"
        />
      </div>
      <div class="main-content">
        <div class="takeaways-section">
          <div class="call-id">Call key takeaways:</div>
          <div>
            @if (modalData?.summary) {
              <markdown
                [data]="modalData?.summary"
                class="custom-markdown !text-xs"
              ></markdown>
            }
          </div>
        </div>
        @if (modalData?.call_url) {
          <div class="audio-frame">
            <swa-audio-player [src]="modalData!.call_url!" />
          </div>
        }
      </div>
    </div>
  } @else {
    <div class="p-5">
      <p-skeleton
        width="60rem"
        height="30rem"
      />
    </div>
  }
</p-dialog>
