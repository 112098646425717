@if (isExternal()) {
  <a
    [href]="link()"
    rel="noopener noreferrer"
    target="blank"
    class="underline"
  >
    {{ displayText() }}
  </a>
} @else {
  <a
    [routerLink]="link()"
    class="underline"
  >
    {{ displayText() }}
  </a>
}
