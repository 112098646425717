import { Routes } from '@angular/router';
import { ShellComponent } from './shell/shell.component';
import {
  ACTIVITIES_MAIN_ROUTE,
  CALLS_ANALYSIS_PATH,
  CALLS_ANALYSIS_ROUTE,
  DEAL_DETAIL_ACTIVITY_ROUTE,
  DEAL_DETAIL_OVERVIEW_ROUTE,
  DEAL_DETAIL_ROUTE,
  LOGIN_ROUTE,
  NEW_DEAL_ROUTE,
  SALES_PIPELINE_MAIN_ROUTE,
} from './shared/constants/routes.constants';
import { unAuthenticatedGuard } from './shared/route-guards/un-authenticated.guard';
import { authenticatedGuard } from './shared/route-guards/authenticated.guard';
import { removeUrlTokenGuard } from './shared/route-guards/remove-url-token.guard';
import { CallAnalysisListComponent } from './activities/call-analysis-list/call-analysis-list.component';
import { CallAnalysisDetailComponent } from './activities/call-analysis-detail/call-analysis-detail.component';
import { NewDealsListComponent } from './sales-pipeline/new-deals-list/new-deals-list.component';
import { DealDetailOverviewComponent } from './sales-pipeline/deal-detail-overview/deal-detail-overview.component';
import { DealDetailActivityComponent } from './sales-pipeline/deal-detail-activity/deal-detail-activity.component';

export const routes: Routes = [
  {
    path: LOGIN_ROUTE,
    loadComponent: () => import('./login/login.component').then((m) => m.LoginComponent),
    canActivate: [unAuthenticatedGuard],
  },
  {
    path: '',
    component: ShellComponent,
    canActivate: [authenticatedGuard, removeUrlTokenGuard],
    children: [
      {
        path: '',
        redirectTo: CALLS_ANALYSIS_PATH,
        pathMatch: 'full',
      },
      {
        path: ACTIVITIES_MAIN_ROUTE,
        loadComponent: () =>
          import('./shared/components/page-tab-container/page-tab-container.component').then(
            (m) => m.PageTabContainerComponent,
          ),
        data: {
          title: 'Activities',
          tabs: [{ label: 'Calls', routerLink: CALLS_ANALYSIS_ROUTE, icon: 'fa-regular fa-phone' }],
        },
        children: [
          {
            path: `${CALLS_ANALYSIS_ROUTE}/:callId`,
            component: CallAnalysisDetailComponent,
            data: { title: 'Call Analysis Details' },
          },
          {
            path: CALLS_ANALYSIS_ROUTE,
            component: CallAnalysisListComponent,
            pathMatch: 'full',
            data: { title: 'Call Analysis' },
          },
        ],
      },
      {
        path: `${SALES_PIPELINE_MAIN_ROUTE}/${DEAL_DETAIL_ROUTE}/:dealId`,
        loadComponent: () =>
          import('./shared/components/page-tab-container/page-tab-container.component').then(
            (m) => m.PageTabContainerComponent,
          ),
        data: {
          title: 'Deal Details',
          tabs: [
            { label: 'Overview', routerLink: `./${DEAL_DETAIL_OVERVIEW_ROUTE}`, icon: 'fa-regular fa-shop' },
            { label: 'Activity', routerLink: `./${DEAL_DETAIL_ACTIVITY_ROUTE}`, icon: 'fa-regular fa-chart-line' },
          ],
        },
        children: [
          {
            path: DEAL_DETAIL_OVERVIEW_ROUTE,
            component: DealDetailOverviewComponent,
            pathMatch: 'full',
            data: { title: 'Deal Overview' },
          },
          {
            path: DEAL_DETAIL_ACTIVITY_ROUTE,
            component: DealDetailActivityComponent,
            pathMatch: 'full',
            data: { title: 'Deal Activity' },
          },
        ],
      },
      {
        path: SALES_PIPELINE_MAIN_ROUTE,
        loadComponent: () =>
          import('./shared/components/page-tab-container/page-tab-container.component').then(
            (m) => m.PageTabContainerComponent,
          ),
        data: {
          title: 'Sales Pipeline',
          tabs: [{ label: 'New Deals', routerLink: NEW_DEAL_ROUTE, icon: 'fa-regular fa-shop' }],
        },
        children: [
          {
            path: NEW_DEAL_ROUTE,
            component: NewDealsListComponent,
            pathMatch: 'full',
            data: { title: 'New Deals' },
          },
        ],
      },
    ],
  },
  {
    path: '**',
    redirectTo: '',
  },
];
